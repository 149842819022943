import React, { Suspense } from "react";
import { useSubheader } from "./../../../_metronic/layout";
import { SettingsEdit } from "./settings/SettingsEdit";
import { Releases } from "./releases/Releases";

import { LayoutSplashScreen, ContentRoute } from "./../../../_metronic/layout";
import { Switch } from "react-router";

export const MobileApplicationsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Mobile Applications");


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>

        <ContentRoute exact={true} path="/mobile-app/settings" component={SettingsEdit} />
        <ContentRoute exact={true} path="/mobile-app/releases" component={Releases} />

      </Switch>
    </Suspense>
  );
};
