import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import * as actions from "../_redux/settings/SettingsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SettingsBasicInfo from "./SettingsBasicInfo";
export function SettingsEdit() {
    const [tab, setTab] = useState("basic");

    const btnRef = useRef();  
    const btnRefReset = useRef();
    const dispatch = useDispatch();
    // Fetch Data
    const { mobileAppSettings } = useSelector(
        (state) => ({ 
            mobileAppSettings: state.mobileAppSettings.mobileAppSettings,
        }),
        shallowEqual
    );
    let initData = {
        "tenant_id": "",
        "logo_url": "",
        "app_display_name": "",
        "background_color": "",
        "default_language": "" 
    };

    const resetForm = () => {
        // Reset
        if (btnRefReset && btnRefReset.current) {
            btnRefReset.current.click();
        }
    }

    const save = () => {
        if (btnRef && btnRef.current) {
            btnRef.current.click();
        }
    };

    const buildAndDeploy = () => {
        alert("Building ...")
    }
    
    useEffect(() => {
        
        dispatch(actions.fetchData());
        
    }, [dispatch]);
    

    return (
        <Card>
            <CardHeader title={"Mobile Application Settings"}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-light ml-2"
                        onClick={() => resetForm()}
                    >
                        <i className="fa fa-redo"></i>
                        Reset
                    </button>
                    {`  `}
                    <button
                        type="submit"
                        className="btn btn-primary ml-2"
                        onClick={save}
                    >
                        Save
                    </button>
                    {`  `}
                    <button
                        type="button"
                        className="btn btn-primary ml-2"
                        onClick={buildAndDeploy }
                    >
                        Build and Deploy
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                    <li className="nav-item" onClick={()=>{setTab("basic")}}>
                        <a
                            className={`nav-link ${tab === "basic" && "active"}`}
                            data-toggle = "tab"
                            role = "tab"
                            aria-selected={(tab==="basic").toString()}
                            >
                        </a>
                    </li>
                </ul>
                <div className="mt-5">
                    {tab === "basic" && (
                        
                        <SettingsBasicInfo 
                            mobileAppSettings={ mobileAppSettings || initData } 
                            btnRef={btnRef}
                            btnRefReset ={btnRefReset}
                            />
                    )}
                </div>
                
            </CardBody>
        </Card>
    );
}