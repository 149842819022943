// update variable advertisingSetting and advertisingSettings

import * as requestFromServer from "../../../../Redux/ReduxSimpleCrud";
import { advertisingSettingSlice, callTypes } from "./AdvertisingSlice";

const BASE_URL = `${process.env.REACT_APP_API_URL}/advertising/settings`;
const { actions } = advertisingSettingSlice;

export const fetchData = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .fetchData(BASE_URL)
        .then(response => {
            const { totalCount, settings } = response.data;
            dispatch(actions.collectionFetched({ totalCount, settings }));
        })
        .catch(error => {
            error.clientMessage = "Can't find advertising settings";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const findData = (id) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .findData(BASE_URL, id)
        .then(response => {
            const { setting } = response.data;
            dispatch(actions.dataFetched({ setting }));
        })
        .catch(error => {
            error.clientMessage = "Can't find advertising setting";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createData = (values) => dispatch => {
    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .createData(BASE_URL, values)
        .then( response => {
            const { setting } = response.data;
            dispatch(actions.dataCreated({ setting }));
        })
        .catch( error => {
            error.clientMessage = "Can't save advertising setting";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const updateData = (id, values) => dispatch => {

    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .updateData(BASE_URL, id, values)
        .then( response => {
            const { setting } = response.data;
            dispatch(actions.dataUpdated({ setting }));
        })
        .catch( error => {
            error.clientMessage = "Can't update advertising setting";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const deleteData = (id) => dispatch => {

    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .deleteData(BASE_URL, id, true)
        .then( response => {
            dispatch(actions.dataDeleted( {'id' : id} ));
        })
        .catch( error => {
            error.clientMessage = "Can't delete advertising setting";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const dataReset = () => {
    return actions.dataReset();
}

