import React, { Suspense } from "react";
import { useSubheader } from "./../../../_metronic/layout";
import { PaymentHistoryCard } from "./PaymentHistoryCard";
import { LayoutSplashScreen, ContentRoute } from "./../../../_metronic/layout";
import { Switch } from "react-router";

export const PaymentHistoryPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Payments History");


  return (
    <PaymentHistoryCard/>
  );
};
