import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import { useHistory } from "react-router-dom";

const initialValues = {
  wp_hostname: "",
  consumer_key: "",
  consumer_secret: "",
  acceptTerms: false,
};

function Registration(props) {
  const history = useHistory();
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    wp_hostname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    consumer_key: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(100, "Maximum 100 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    consumer_secret: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(100, "Maximum 100 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      register(values.wp_hostname, values.consumer_key, values.consumer_secret)
        .then(() => {
          history.push('/auth/login');
          disableLoading();
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "Something went wrong. Please try again",
            })
          );
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Account Activation
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your Wordpress account details
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Wordpress hostname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Wordpress hostname"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "wp_hostname"
            )}`}
            name="wp_hostname"
            {...formik.getFieldProps("wp_hostname")}
          />
          {formik.touched.wp_hostname && formik.errors.wp_hostname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.wp_hostname}</div>
            </div>
          ) : null}
        </div>
        {/* end: Wordpress hostname */}

        {/* begin: Consumer key */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Consumer key"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "consumer_key"
            )}`}
            name="consumer_key"
            {...formik.getFieldProps("consumer_key")}
          />
          {formik.touched.consumer_key && formik.errors.consumer_key ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.consumer_key}</div>
            </div>
          ) : null}
        </div>
        {/* end: Consumer key */}

        {/* begin: Consumer secret */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Consumer secret"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "consumer_secret"
            )}`}
            name="consumer_secret"
            {...formik.getFieldProps("consumer_secret")}
          />
          {formik.touched.consumer_secret && formik.errors.consumer_secret ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.consumer_secret}</div>
            </div>
          ) : null}
        </div>
        {/* end: Consumer secret */}


        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              I agree the Terms & Conditions
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            {/* <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button> */}
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
