import React, { Suspense } from "react";
import { useSubheader } from "./../../../_metronic/layout";
import { Categories } from "./categories/Categories";
import { CategoryEdit } from "./categories/CategoriesEdit";
import { PaymentSettings } from "./payment_settings/PaymentSettings";
import { PaymentSettingEdit } from "./payment_settings/PaymentSettingEdit";
import { Advertising } from "./advertising/Advertising";
import { AdvertisingEdit } from "./advertising/AdvertisingEdit";
import { TenantAttributeEdit } from "./tenant-attributes/TenantAttributes";
// import { ShippingZone } from "./shipping-zone/ShippingZone";
// import { ShippingZoneEdit } from "./shipping-zone/ShippingZoneEdit";

import { LayoutSplashScreen, ContentRoute } from "./../../../_metronic/layout";
import { Switch } from "react-router";

export const SettingsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Settings");


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact={true} path="/settings/categories" component={Categories} />
        <ContentRoute exact={true} path="/settings/categories/new" component={CategoryEdit} />
        <ContentRoute exact={true} path="/settings/categories/:id/edit" component={CategoryEdit} />

        <ContentRoute exact={true} path="/settings/payment_settings" component={PaymentSettings} />
        <ContentRoute exact={true} path="/settings/payment_settings/new" component={PaymentSettingEdit} />
        <ContentRoute exact={true} path="/settings/payment_settings/:id/edit" component={PaymentSettingEdit} />

        <ContentRoute exact={true} path="/settings/advertising" component={Advertising} />
        <ContentRoute exact={true} path="/settings/advertising/new" component={AdvertisingEdit} />
        <ContentRoute exact={true} path="/settings/advertising/:id/edit" component={AdvertisingEdit} />
        <ContentRoute exact={true} path="/settings/tenant-attributes" component={TenantAttributeEdit} />
        {/* <ContentRoute exact={true} path="/settings/shipping/zones" component={ShippingZone} />
        <ContentRoute exact={true} path="/settings/shipping/zones/new" component={ShippingZoneEdit} />
        <ContentRoute exact={true} path="/settings/shipping/zones/:id/edit" component={ShippingZoneEdit} /> */}

      </Switch>
    </Suspense>
  );
};
