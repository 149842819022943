import axios from "axios";

export const PRODUCTS_URL = `${process.env.REACT_APP_API_URL}/products`;

const pagination = `?order=asc&orderBy=id&per_page=100&`;
const tenantInfo = `tenant_id=hn_` + window.location.hostname;
export function fetchProducts() {

    return axios.get(PRODUCTS_URL + "/web_products" + pagination + "&" + tenantInfo);
}

export function findProduct(id) {

    return axios.get(PRODUCTS_URL + "/" + id + pagination + "&" + tenantInfo);

}

export function createProduct(values) {

    return axios.post(PRODUCTS_URL + "?" + tenantInfo, values);

}

export function updateProduct(id, values) {

    return axios.put(PRODUCTS_URL + "/" + id + "?" + tenantInfo, values);

}

export function deleteProduct(id) {

    return axios.delete(PRODUCTS_URL + "/" + id + "?" + tenantInfo);

}

export function deleteProducts(ids) {

    return axios.delete(PRODUCTS_URL + "/batch-delete?" + tenantInfo, {data : {"ids" : ids}});

}

export function searchProducts(keyword) {

    return axios.get(PRODUCTS_URL + "/web_products" + pagination + "&search=" + keyword + "&" + tenantInfo);
    
}