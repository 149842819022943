import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "./../../../../_metronic/_partials/controls";
import { forEach } from "lodash";
import * as actions from "../_redux/advertising/AdvertisingActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers";
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import CategoryAutoSuggest from "./category-autosuggest/CategoryAutoSuggest";

// Validation schema
const EditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Model is required"),
    description: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 500 symbols")
});

export default function AdvertisingBasicInfo({
    advertiser,
    save,
    btnRef,
    btnRefReset
}) {
    // const classes = useStyles();
    const dispatch = useDispatch();
    const regex = /(<([^>]+)>)/ig;
    let initData = { ...advertiser };
    initData.description = initData.description.replace(regex, '');
    forEach(initData, (value, key) => {
        if (key === 'active') {
            initData[key] = value ? true : false;
        }
        if (value === undefined || value === null) {
            initData[key] = "";
        }
        
    });
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [status, setDialogStatus] = useState("Settings successfully saved!");
    function handleCloseModal() { setOpen(false); history.push("/settings/advertising"); }
    useEffect(() => {
        document.getElementById("category_name").value = initData.category_name;
        document.getElementById("category_id").value = initData.category_id;
    }, [initData]);
    
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initData}
                validationSchema={EditSchema}
                onSubmit={(values, { setState, setSubmitting }) => {
                    values.category_name = document.getElementById("category_name").value;
                    values.category_id = document.getElementById("category_id").value;
                    if (values.id) {
                        dispatch(actions.updateData(values.id, values)).then((response) => {
                            setDialogStatus("Settings successfully updated!")
                            setOpen(true);
                            setSubmitting(false);
                        });
                    }
                    else {

                        dispatch(actions.createData(values)).then((response) => {
                            setOpen(true);
                            setSubmitting(false);
                        });
                    }


                }}
            >
                {({
                    handleSubmit,
                    resetForm,
                    values,
                    setFieldValue }) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <CategoryAutoSuggest
                                        defaultValues = {initData}
                                    />
                                </div>
                                <div className="col-lg-4 visibility">
                                    <label>
                                        Active
                                    </label>
                                    <Switch
                                        name="active"
                                        value="true"
                                        checked={values.active}
                                        onChange={(event, checked) => {
                                            setFieldValue("active", checked);
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        name="name"
                                        component={Input}
                                        placeholder="Advertiser name"
                                        label="Advertiser Name"
                                    />
                                </div>
                                <div className="col-lg-8">
                                    <Field
                                        name="description"
                                        component={Input}
                                        placeholder="Description"
                                        label="Description"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <TextField
                                        className="col-lg-12"
                                        name="script"
                                        id="outlined-multiline-static"
                                        label="HTML Script"
                                        placeholder="HTML Script"
                                        multiline
                                        rows={4}
                                        defaultValue={values.script || ""}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(event) => {
                                            values.script = event.target.value;
                                        }}

                                    />
                                </div>

                            </div>

                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                // Reset Button
                                type="button"
                                style={{ display: "none" }}
                                ref={btnRefReset}
                                onClick={resetForm}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>

            <Dialog
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="textPrimary">
                        <span className="svg-icon svg-icon-md svg-icon-primary" >
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                            />
                        </span>
                        {status}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );


}