import React from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "./../../../_metronic/_partials/controls";
import ProductsTable from "./products-table/ProductsTable";

export function ProductsCard() {
  const history = useHistory();
  const routeChange = () => {
    history.push("/products/new");
  }
  return (
    <Card>
      <CardHeader title="Products list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={routeChange}
          >
            New Product
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
       
        <ProductsTable />
      </CardBody>
    </Card>
  );
}
