import React, { useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "./../../../../_metronic/_partials/controls";
import { forEach } from "lodash";
import { PRODUCT_STOCK_STATUSES } from "../ProductsUIHelper";
import * as actions from "../_redux/productsActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers";


// Validation schema
const ProductEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Model is required"),
    regular_price: Yup.number()
        .min(0, "0 is minimum")
        .max(1000000, "1000000 is maximum"),
    sale_price: Yup.number()
        .min(0, "0 is minimum")
        .max(1000000, "1000000 is maximum"),
    stock_quantity: Yup.number()
        .min(0, "0 is minimum")
        .max(1000000, "1000000 is maximum")

});

export default function ProductBasicInfo({
    product,
    saveProduct,
    btnRef,
    btnRefReset
}) {
    const dispatch = useDispatch();
    const regex = /(<([^>]+)>)/ig;
    let initProduct = { ...product };
    initProduct.short_description = initProduct.short_description.replace(regex, '');
    forEach(initProduct, (value, key) => {
        if (value === undefined || value === null) {
            initProduct[key] = "";
        }
    });
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [status, setDialogStatus] = useState("Product successfully saved!");

    const img = useRef([]);

    function handleCloseModal() { setOpen(false); history.push("/products"); }

    const handlePhoto = (e) => {
        var reader = new FileReader();
        reader.readAsDataURL(e.currentTarget.files[0]);
        let fileName = e.currentTarget.files[0].name;
        reader.onload = (inp) => {
            img.current.push({
                file: reader.result,
                name: fileName
            });
        };

        reader.onerror = (error) => {
            img.current = [];
        };
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initProduct}
                validationSchema={ProductEditSchema}
                onSubmit={(values, { setState, setSubmitting }) => {

                    values.stock_quantity = Number(values.stock_quantity);
                    values.img = img.current

                    if (values.id) {
                        dispatch(actions.updateProduct(values.id, values)).then((response) => {
                            setDialogStatus("Product successfully updated!")
                            setOpen(true);
                            setSubmitting(false);
                        });
                    }
                    else {
                        dispatch(actions.createProduct(values)).then((response) => {
                            setOpen(true);
                            setSubmitting(false);
                        });
                    }


                }}
            >
                {({ handleSubmit, resetForm }) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        name="name"
                                        component={Input}
                                        placeholder="Product name"
                                        label="Product Name"
                                    />
                                </div>
                                <div className="col-lg-8">
                                    <Field
                                        name="short_description"
                                        component={Input}
                                        placeholder="Short description"
                                        label="Short description"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        name="sku"
                                        component={Input}
                                        placeholder="SKU"
                                        label="SKU"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        name="regular_price"
                                        component={Input}
                                        placeholder="Regular Price"
                                        label="Regular Price"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        name="sale_price"
                                        component={Input}
                                        placeholder="Sale Price"
                                        label="Sale Price"
                                    />
                                </div>

                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        name="stock_quantity"
                                        component={Input}
                                        placeholder="Stock Quantity"
                                        label="Stock Quantity"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Select name="stock_status" label="Stock Status">
                                        {PRODUCT_STOCK_STATUSES.map((stock_status) => (
                                            <option key={stock_status} value={stock_status}>
                                                {stock_status}
                                            </option>
                                        ))}
                                    </Select>
                                </div>

                                <div className="col-lg-4">
                                    <Field
                                        name="photo"
                                        component={Input}
                                        type='file'
                                        accept="image/png,image/jpeg"
                                        placeholder="Stock Photo"
                                        label="Stock Photo"
                                        onChange={handlePhoto}
                                    />
                                </div>

                            </div>
                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                // Reset Button
                                type="button"
                                style={{ display: "none" }}
                                ref={btnRefReset}
                                onClick={resetForm}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>

            <Dialog
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="textPrimary">
                        <span className="svg-icon svg-icon-md svg-icon-primary" >
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                            />
                        </span>
                        {status}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}