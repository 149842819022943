import axios from "axios";

// export const BASE_URL = `${process.env.REACT_APP_API_URL}/products/categories`;

const pagination = `?order=asc&orderBy=id&per_page=100&`;
const tenantInfo = `tenant_id=hn_` + window.location.hostname;
export function fetchData(BASE_URL) {

    return axios.get(BASE_URL + pagination + "&" + tenantInfo + "&web_api=true");
}

export function findData(BASE_URL, id) {

    return axios.get(BASE_URL + "/" + id + pagination + "&" + tenantInfo);

}

export function createData(BASE_URL, values, config = null) {

    return axios.post(BASE_URL + "?" + tenantInfo, values);

}

export function updateData(BASE_URL, id, values, config = null) {

    return axios.put(BASE_URL + "/" + id + "?" + tenantInfo, values);

}

export function deleteData(BASE_URL, id, force = false) {

    return axios.delete(BASE_URL + "/" + id + "?" + tenantInfo + "&force=" + force);

}