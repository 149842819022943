import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "./../../../../_metronic/_partials/controls";
import { useSubheader } from "./../../../../_metronic/layout";
import { useHistory } from "react-router-dom";
import ProductBasicInfo from "./ProductBasicInfo";
import * as actions from "../_redux/productsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PRODUCT_STOCK_STATUSES } from "../ProductsUIHelper";


export function ProductsEdit({
    match: {
        params: { id }
    }
}) {
    const btnRef = useRef();
    const btnRefReset = useRef();
    // const resetBtnRef = useRef();
    const dispatch = useDispatch();
    // Fetch product
    const { actionsLoading, productForEdit } = useSelector(
        (state) => ({
            actionsLoading: state.products.actionsLoading,
            productForEdit: state.products.productForEdit,
        }),
        shallowEqual
    );

    let initProduct = {
        "id": undefined,
        "name": "",
        "short_description": "",
        "sku": "",
        "regular_price": "",
        "sale_price": "",
        "stock_quantity": "",
        "stock_status": PRODUCT_STOCK_STATUSES[0],
    };

    const history = useHistory();
    const [title, setTitle] = useState("");
    const [tab, setTab] = useState("basic");
    // const [productInfo, setProductInfo] = useState(initProduct);
    const gotoProductsPage = () => {
        // Reset
        dispatch(actions.productReset());
        history.push("/products");
    }

    const resetForm = () => {
        // Reset
        if (btnRefReset && btnRefReset.current) {
            btnRefReset.current.click();
        }
    }

    const saveProduct = () => {
        if (btnRef && btnRef.current) {
            btnRef.current.click();
        }
    };

    useEffect(() => {

        if (id !== undefined) {
            dispatch(actions.findProduct(id));
        }

    }, [id, dispatch]);

    // End of fetching
    useEffect(() => {
        let _title = (id !== undefined) ? "" : "New Product";
        if (productForEdit) {
            _title = `Edit Product - `;
            _title += (!actionsLoading && productForEdit) ? `${productForEdit.name}` : ``;
            // setProductInfo({...productForEdit});
        }
        setTitle(_title);

    }, [productForEdit, id]);

    return (
        <Card>
            <CardHeader title={title}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => gotoProductsPage()}
                    >
                        Back
                    </button>
                    {`  `}
                    <button
                        type="button"
                        className="btn btn-light ml-2"
                        onClick={() => resetForm()}
                    >
                        <i className="fa fa-redo"></i>
                        Reset
                    </button>
                    {`  `}
                    <button
                        type="submit"
                        className="btn btn-primary ml-2"
                        onClick={saveProduct}
                    >
                        Save
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                    <li className="nav-item" onClick={() => { setTab("basic") }}>
                        <a
                            className={`nav-link ${tab === "basic" && "active"}`}
                            data-toggle="tab"
                            role="tab"
                            aria-selected={(tab === "basic").toString()}
                        >
                            Basic Info
                        </a>
                    </li>
                </ul>
                <div className="mt-5">
                    {tab === "basic" && (

                        <ProductBasicInfo
                            product={productForEdit || initProduct}
                            saveProduct={saveProduct}
                            btnRef={btnRef}
                            btnRefReset={btnRefReset}
                        />
                    )}
                </div>

            </CardBody>
        </Card>
    );
}
