import * as requestFromServer from "../../../../Redux/ReduxSimpleCrud";
import { categorySlice, callTypes } from "./categoriesSlice";

const BASE_URL = `${process.env.REACT_APP_API_URL}/products/categories`;
const { actions } = categorySlice;

export const fetchCategories = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .fetchData(BASE_URL)
        .then(response => {
            const { totalCount, categories } = response.data;
            dispatch(actions.categoriesFetched({ totalCount, categories }));
        })
        .catch(error => {
            error.clientMessage = "Can't find categories";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const findCategory = (id) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .findData(BASE_URL, id)
        .then(response => {
            delete response.data.category.image;
            const { category } = response.data;
            dispatch(actions.categoryFetched({ category }));
        })
        .catch(error => {
            error.clientMessage = "Can't find category";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createCategory = (values) => dispatch => {
    console.log(values);
    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .createData(BASE_URL, values, { headers: { 'content-type': 'multipart/form-data' } })
        .then( response => {
            const { category } = response.data;
            dispatch(actions.categoryCreated({ category }));
        })
        .catch( error => {
            error.clientMessage = "Can't save category";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const updateCategory = (id, values) => dispatch => {

    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .updateData(BASE_URL, id, values)
        .then( response => {
            const { category } = response.data;
            dispatch(actions.categoryUpdated({ category }));
        })
        .catch( error => {
            error.clientMessage = "Can't update category";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const deleteCategory = (id) => dispatch => {

    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .deleteData(BASE_URL, id, true)
        .then( response => {
            const { category } = response.data;
            dispatch(actions.categoryDeleted({ category }));
        })
        .catch( error => {
            error.clientMessage = "Can't delete category";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const categoryReset = () => {
    return actions.categoryReset();
}

