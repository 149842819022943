import {createSlice} from "@reduxjs/toolkit";

const initialPaymentSettingsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  paymentSettings: null,
  paymentSettingsForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const paymentSettingsSlice = createSlice({
  name: "payment_settings",
  initialState: initialPaymentSettingsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    
    paymentSettingsFind: (state, action) => {
      state.actionsLoading = false;
      state.paymentSettingsForEdit = action.payload.payment_settings;
      state.error = null;
    },
    
    paymentSettingsFetched: (state, action) => {
      
      const { totalCount, payment_settings } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.paymentSettings = payment_settings;
      state.totalCount = totalCount;
    },
    
    paymentSettingsCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.paymentSettings.push(action.payload.payment_settings);
    },
    
    paymentSettingsUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { payment_settings } = action.payload;
      state.paymentSettings.forEach( (entity, key) => {
        if ( entity.id === payment_settings.id ) {
          state.paymentSettings[key] = {...payment_settings};
        }
      } );
    },
    
    paymentSettingsDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { payment_settings } = action.payload;
      state.paymentSettings.forEach( (entity, key) => {
        if ( entity.id === payment_settings.id ) {
            delete state.paymentSettings[key];
        }
      } );
    },
    
    paymentSettingsReset: (state) => {
      state.paymentSettingsForEdit = null;
    },

    showSecret: (state, action) => {
      
      state.paymentSettings.forEach( (entity, key) => {
        if ( entity.id == action.payload ) {
            state.paymentSettings[key].visible = true;
            console.log('show', action.payload);
        }
      } );
    }
    
  }
});
