import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/PaymentHistoryActions";
import { createData, TABLE_HEADER } from '../_redux/PaymentHistory';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from '@material-ui/core/Paper';
import SVG from "react-inlinesvg";
import { makeStyles } from '@material-ui/core/styles';
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers";
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        boxShadow: 'none !important'
    },
    table: {
        minWidth: 650,
    },
    thead: {
        whiteSpace: 'nowrap',
        fontWeight: 600,
        color: '#B5B5C3',
        textTransform: 'uppercase',
        padding: '.75rem',
        fontSize: '.9rem !important',
        fontFamily: 'Poppins,Helvetica,"sans-serif',
        letterSpacing: '.1rem'
    },
    actionColumn: {
        whiteSpace: 'nowrap',
        fontWeight: 600,
        color: '#B5B5C3',
        textTransform: 'uppercase',
        padding: '.75rem',
        fontSize: '.9rem !important',
        fontFamily: 'Poppins,Helvetica,"sans-serif',
        letterSpacing: '.1rem',
        minWidth: '110px !important'
    },
    td: {
        padding: '.75rem',
        fontSize: '13px !important',
        fontWeight: '400',
        fontFamily: 'Poppins,Helvetica,"sans-serif'
    },
    links: {
        display: "inline-block",
        width: "180px",
        whiteSpace: "nowrap",
        overflow: "hidden !important",
        textOverflow: "ellipsis"
    },
    oneLineText: {
        whiteSpace: 'nowrap !important',
        padding: '.75rem',
        fontSize: '13px !important',
        fontWeight: '400',
        fontFamily: 'Poppins,Helvetica,"sans-serif'
    }
}));

let rows = [];
export default function PaymentHistoryTable() {
    const classes = useStyles();
    const { currentState } = useSelector((state) => ({ currentState: state.payment_history }), shallowEqual);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    
    // Pagination
    const handleChangePage = (event, newPage) => { setPage(newPage); };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const dispatch = useDispatch();
    useEffect(() => { dispatch(actions.fetchPaymentHistory()); }, []);
    
    if (!currentState.listLoading && currentState.payment_history) {
        rows = [];
        currentState.payment_history.forEach(history => {
            rows.push(
                createData(
                    history.id,
                    history.payment_id,
                    history.order_id,
                    history.woo_order_id,
                    history.woo_customer_id,
                    history.gateway,
                    history.payment_reference,
                    history.payment_source,
                    history.invoice_from_gateway,
                    history.status,
                    history.date_created,
                    history.date_modified
                )
            );
        });
    }

    return (
        <Card>
            <CardBody>
                <TableContainer component={Paper} className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {TABLE_HEADER.map((header) => {
                                    switch (header.name) {
                                        case "woo_order_id":
                                        case "woo_customer_id":
                                        case "dateCreated":
                                        case "dateModified":
                                            return <TableCell className={classes.thead} key={header.name} align="right">{header.title}</TableCell>;
                                        case "actions":
                                        case "gateway":
                                        case "status":
                                        case "id":
                                            return <TableCell className={classes.thead} key={header.name} align="center">{header.title}</TableCell>;
                                        default:
                                            return <TableCell className={classes.thead} key={header.name} align="left">{header.title}</TableCell>;
                                    }

                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    let customerLink = "https://woo-test.nxt.work/wp-admin/user-edit.php?user_id=" + row.woo_customer_id;
                                    let orderLink = "https://woo-test.nxt.work/wp-admin/post.php?post="+row.woo_order_id+"&action=edit";
                                    return(
                                        <TableRow key={row.id}>
                                            <TableCell className={classes.td} align="left">{row.id}</TableCell>
                                            <TableCell className={classes.td} align="left">{row.payment_id}</TableCell>
                                            <TableCell className={classes.td} align="left">{row.order_id}</TableCell>
                                            <TableCell className={classes.td} align="right"><a target="_blank" href={orderLink}>{row.woo_order_id}</a></TableCell>
                                            <TableCell className={classes.td} align="right"><a target="_blank" href={customerLink}>{row.woo_customer_id}</a></TableCell>
                                            <TableCell className={classes.links, classes.td} align="center">
                                                <img src={toAbsoluteUrl(row.gateway)}/>
                                            </TableCell>
                                            <TableCell className={classes.td} align="left">{row.payment_reference}</TableCell>
                                            <TableCell className={classes.td} align="left">{row.payment_source}</TableCell>
                                            <TableCell className={classes.td} align="left"><a target="_blank" href={row.invoice_from_gateway}>View</a></TableCell>
                                            <TableCell className={classes.td} align="left">{row.status}</TableCell>
                                            <TableCell className={classes.oneLineText} align="right">{row.dateCreated}</TableCell>
                                            <TableCell className={classes.oneLineText} align="right">{row.dateModified}</TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </CardBody>
        </Card>
        
    );
}