import dateFormat from "dateformat";
export function createData(
    id,
    version,
    date_released,
    app_link) {

    let dateReleased = dateFormat(date_released, 'yyyy-mm-dd HH:MM:ss');

    return {
        id,
        version,
        dateReleased,
        app_link
    };
}

export const TABLE_HEADER = [
        {
            name : "version",
            title : "Version"
        },
        {
            name : "app_link",
            title : "App Link"
        },
        {
            name : "dateReleased",
            title : "Date Released"
        }
        
    ];