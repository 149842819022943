import dateFormat from "dateformat";
export function createData(
    id,
    payment_id,
    order_id,
    woo_order_id,
    woo_customer_id,
    gateway,
    payment_reference,
    payment_source,
    invoice_from_gateway,
    status,
    date_created,
    date_modified) {

    let dateCreated = dateFormat(date_created, 'yyyy-mm-dd HH:MM:ss');
    let dateModified = dateFormat(date_modified, 'yyyy-mm-dd HH:MM:ss');

    return {
        id,
        payment_id,
        order_id,
        woo_order_id,
        woo_customer_id,
        gateway,
        payment_reference,
        payment_source,
        invoice_from_gateway,
        status,
        dateCreated,
        dateModified
    };
}

export const TABLE_HEADER = [
    {
        name : "id",
        title : "ID"
    },
    {
        name: "payment_id",
        title: "Payment ID"
    },
    {
        name: "order_id",
        title: "Order ID"
    },
    {
        name: "woo_order_id",
        title: "Woo Order ID"
    },
    {
        name: "woo_customer_id",
        title: "Woo Customer ID"
    },
    {
        name: "gateway",
        title: "Gateway"
    },
    {
        name: "payment_reference",
        title: "Payment Reference"
    },
    {
        name: "payment_source",
        title: "Payment Source"
    },
    {
        name: "invoice_from_gateway",
        title: "Invoice"
    },
    {
        name: "status",
        title: "Status"
    },
    {
        name: "dateCreated",
        title: "Created"
    },
    {
        name: "dateModified",
        title: "Modified"
    }

];