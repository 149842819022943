import * as requestFromServer from "../../../../Redux/ReduxSimpleCrud";
import { releasesSlice, callTypes } from "./ReleasesSlice";

const BASE_URL = `${process.env.REACT_APP_API_URL}/releases`;
const { actions } = releasesSlice;

export const fetchReleases = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .fetchData(BASE_URL)
        .then(response => {
            const { totalCount, releases } = response.data;
            dispatch(actions.releasesFetched({ totalCount, releases }));
        })
        .catch(error => {
            error.clientMessage = "Can't find list of releases";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};


