/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/products", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/products">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")} />
            </span>
            <span className="menu-text">Products</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/payments/history", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/payments/history">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")} />
            </span>
            <span className="menu-text">Payments History</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/* SETTINGS */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/react-bootstrap",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/settings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/settings.svg")} />
            </span>
            <span className="menu-text">Settings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Settings</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/settings/categories"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/settings/categories">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Categories</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/settings/payment_settings"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/settings/payment_settings">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Payment Settings</span>
                  </NavLink>
                </li>
                {/* Advertiser Settings */}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/settings/advertising"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/settings/advertising">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Advertising Settings</span>
                  </NavLink>
                </li>
                {/* Tenant Attribute Settings */}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/settings/tenant-attributes"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/settings/tenant-attributes">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Tenant Attributes</span>
                  </NavLink>
                </li>


                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/* end::Menu Nav */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/react-bootstrap",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/mobile-app">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Android.svg")} />
            </span>
            <span className="menu-text">Mobile App</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Mobile App</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/mobile-app/releases"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/mobile-app/releases">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Releases</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/mobile-app/settings"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/mobile-app/settings">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Settings</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>
      </ul>
    </>
  );
}
