// update variable country and countries
import {createSlice} from "@reduxjs/toolkit";

const initialDataState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  countries: [],
  countryForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const countrySlice = createSlice({
  name: "countries",
  initialState: initialDataState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    
    dataFetched: (state, action) => {
      state.actionsLoading = false;
      state.countryForEdit = action.payload.country;
      state.error = null;
    },
    
    collectionFetched: (state, action) => {
      
      const { totalCount, countries } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.countries = countries;
      state.totalCount = totalCount;
    },
    
  }
});
