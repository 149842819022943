// update variable location and locations
import {createSlice} from "@reduxjs/toolkit";

const initialDataState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  locations: null,
  locationForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const shippingLocationSlice = createSlice({
  name: "locations",
  initialState: initialDataState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    
    dataFetched: (state, action) => {
      state.actionsLoading = false;
      state.locationForEdit = action.payload.location;
      state.error = null;
    },
    
    collectionFetched: (state, action) => {
      
      const { totalCount, locations } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.locations = locations;
      state.totalCount = totalCount;
    },
    
    dataCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.locations.push(action.payload.location);
    },
    
    dataUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { location } = action.payload;
      state.locations.forEach( (entity, key) => {
        if ( entity.id === location.id ) {
          state.locations[key] = {...location};
        }
      } );
    },
    
    dataDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { location } = action.payload;
      state.locations.forEach( (entity, key) => {
        if ( entity.id === location.id ) {
            delete state.locations[key];
        }
      } );
    },
    
    collectionDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.locations = state.locations.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    
    collectionsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.locations = state.locations.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    
    dataReset: (state) => {
      state.locationForEdit = null;
    },
    
  }
});
