import { TextField } from "@material-ui/core";
import React from "react";
import Autosuggest from "react-autosuggest";
import "./autosuggest.css";

// Tutorial from https://www.freakyjolly.com/autocomplete-suggestion-control-using-react-autosuggest/

class CategoryAutoSuggest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            category_id : 0,
            suggestions: []
        };

    }

    onSelectCategory = () => {
        return this.state.value;
    }

    // Filter Logic
    getSuggestions = async (value) => {

        const inputValue = value.trim().toLowerCase();
        let response = await fetch("http://localhost/api/products/categories/?tenant_id=95E61DA_1633057268&search=" + inputValue + "&apikey=a591bb53");
        let data = await response.json();
        return data;

    };

    // Trigger Suggestions
    getSuggestionValue = suggestion =>{
        this.setState({
            category_id: suggestion.id
        })
        return suggestion.name
    };

    // Render each option
    renderSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );

    // Onchange event handler
    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    // Suggestion rerender when user types
    onSuggestionsFetchRequested = ({ value }) => {

        if (value.length > 2) {
            this.getSuggestions(value)
                .then(data => {
                    if (data.categories.length > 0) {
                        this.setState({
                            suggestions: data.categories
                        });
                    } else {
                        this.setState({
                            suggestions: []
                        });
                    }
                });
        } else {
            this.setState({
                suggestions: []
            });
        }

    };

    // Triggered on clear
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;

        // Options props
        const inputProps = {
            placeholder: 'Category name',
            value,
            onChange: this.onChange,
            id: "category_name",
            name: "category_name",
        };

        // Adding AutoSuggest component
        return (
            <>
                <TextField
                    hidden={true}
                    value={this.state.category_id}
                    name="category_id"
                    id="category_id"
                />
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                />
            </>
        );
    }

}

export default CategoryAutoSuggest;