import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/releases/ReleasesActions";
import { createData, TABLE_HEADER } from './../_redux/releases/Releases';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar
} from "./../../../../_metronic/_partials/controls";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    keys : {
        wordBreak: "break-word",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: "16px", /* fallback */
        maxHeight: "32px", /* fallback */
        maxWidth: "320px"
    }
}));

export function Releases() {
    let rows = [];
    const classes = useStyles();
    const { currentState } = useSelector((state) => ({ currentState: state.releases }), shallowEqual);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // Pagination
    const handleChangePage = (event, newPage) => { setPage(newPage); };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // End of Pagination

    // Popullate table
    const dispatch = useDispatch();
    useEffect(() => { dispatch(actions.fetchReleases()); }, []);
    if (!currentState.listLoading && currentState.releases) {
        rows = [];
        currentState.releases.forEach(releases => {
            rows.push(
                createData(
                    releases.id,
                    releases.version,
                    releases.date_released,
                    releases.app_link
                )
            );
        });
    }
    
    return (
        <Card>
            <CardHeader title="Releases">
               
            </CardHeader>
            <CardBody>
                <TableContainer component={Paper} className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {TABLE_HEADER.map((header) => {
                                    switch (header.name) {
                                        case "dateReleased":
                                            return <TableCell className={classes.thead} key={header.name} align="right">{header.title}</TableCell>;
                                        case "version":
                                            return <TableCell className={classes.thead} key={header.name} align="center">{header.title}</TableCell>;
                                        default:
                                            return <TableCell className={classes.thead} key={header.name} align="left">{header.title}</TableCell>;
                                    }

                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell className={classes.td} align="center">{row.version}</TableCell>
                                        <TableCell className={classes.td, classes.keys} align="left">{row.app_link}</TableCell>
                                        <TableCell className={classes.oneLineText, classes.td} align="right">{row.dateReleased}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </CardBody>
        </Card>
    )
}