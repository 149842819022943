import * as requestFromServer from "../../../Redux/ReduxSimpleCrud";
import { paymentHistorySlice, callTypes } from "./PaymentHistorySlice";

const BASE_URL = `${process.env.REACT_APP_API_URL}/payment/history`;
const { actions } = paymentHistorySlice;

export const fetchPaymentHistory = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .fetchData(BASE_URL)
        .then(response => {
            const { totalCount, payment_history } = response.data;
            dispatch(actions.paymentHistoryFetched({ totalCount, payment_history }));
        })
        .catch(error => {
            error.clientMessage = "Can't find payment hisotry";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

