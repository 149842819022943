import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "./../_redux/productsActions";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from '@material-ui/core/Paper';
import { TABLE_HEADER } from './Products';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSortableData } from "../../../Utils/SortableData";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        boxShadow: 'none !important'
    },
    table: {
        minWidth: 650,
    },
    thead: {
        whiteSpace: 'nowrap',
        fontWeight: 600,
        color: '#B5B5C3',
        textTransform: 'uppercase',
        padding: '.75rem',
        fontSize: '.9rem !important',
        fontFamily: 'Poppins,Helvetica,"sans-serif',
        letterSpacing: '.1rem'
    },
    actionColumn: {
        whiteSpace: 'nowrap',
        fontWeight: 600,
        color: '#B5B5C3',
        textTransform: 'uppercase',
        padding: '.75rem',
        fontSize: '.9rem !important',
        fontFamily: 'Poppins,Helvetica,"sans-serif',
        letterSpacing: '.1rem',
        minWidth: '110px !important'
    },
    td: {
        padding: '.75rem',
        fontSize: '13px !important',
        fontWeight: '400',
        fontFamily: 'Poppins,Helvetica,"sans-serif'
    },
    oneLineText: {
        whiteSpace: 'nowrap'
    },
    bold: {
        fontWeight: '600',
        color: '#000'
    },
    searchContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    searchBox: {
        width: "300px",
        height: "2rem",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    onLoadingWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "500px"
    },
    loadingLabel: {
        marginLeft: "40px",
        marginTop: "5px"
    },
    onLoadingDialogWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: "20px 50px"
    },
    filterAction: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    }
}));



let rows = [];

export default function ProductsTable(props) {
    const { currentState } = useSelector((state) => ({ currentState: state.products }), shallowEqual);
    const history = useHistory();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = useState(false);
    const [deleteProduct, setToDeleteProduct] = useState({ id: 0, sku: '' });
    const handleChangePage = (event, newPage) => { setPage(newPage); };
    const dispatch = useDispatch();
    const { items, requestSort, sortConfig, requestItemSelectedUpdate, requestSelectAll, bulkSelectedItems } = useSortableData(currentState.products);
    useEffect(() => { dispatch(actions.fetchProducts()); }, []);

    // BULK DELETE
    const [bulkSelect, showBulkSelect] = useState(false);
    const [openBatchDeleteModal, setOpenBatchDeleteModal] = useState(false);

    const gotoEditPage = (id) => { history.push('/products/' + id + '/edit'); }
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    function handleOpenDeleteModal(id, sku) { setToDeleteProduct({ id, sku }); setOpen(true); }
    function handleCloseDeleteModal() { setOpen(false); }
    function requestToDelete() {
        dispatch(actions.deleteProduct(deleteProduct.id)).then(() => {
            dispatch(actions.fetchProducts());
            let searchBox = document.querySelectorAll("div[dataid='search-input'] input");
            searchBox[0].value = "";
            handleCloseDeleteModal();
            setPage(0);
            requestSelectAll(false);
            showBulkSelect(false);
        })
    }
    
    // BULK DELETE
    function handleOpenBatchDeleteModal() { setOpenBatchDeleteModal(true); }
    function handleCloseBatchDeleteModal() { setOpenBatchDeleteModal(false); }
    function requestToBatchDelete() {
        dispatch(actions.deleteProducts(bulkSelectedItems)).then(() => {
            dispatch(actions.fetchProducts());
            let searchBox = document.querySelectorAll("div[dataid='search-input'] input");
            searchBox[0].value = "";
            handleCloseBatchDeleteModal();
            setPage(0);
            requestSelectAll(false);
            showBulkSelect(false);
        })
    }

    let searchTimer = null;
    return (
        <>
            <div className={classes.searchContainer} >
                <div className={classes.filterAction}>
                    <button
                        disabled={currentState.listLoading || currentState.actionsLoading}
                        hidden={bulkSelect}
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                            showBulkSelect(true);
                        }}
                    >
                        Bulk Select
                    </button>
                    <button
                        hidden={!bulkSelect}
                        type="button"
                        className="btn btn-danger"
                        style={{ marginRight: '15px' }}
                        disabled={bulkSelectedItems.length <= 0}
                        onClick={handleOpenBatchDeleteModal}
                    >
                        Delete Selected
                    </button>
                    <button
                        hidden={!bulkSelect}
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                            showBulkSelect(false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
                <TextField
                    dataid="search-input"
                    id="outlined-dense"
                    label="Search in all fields"
                    className={classes.searchBox}
                    margin="dense"
                    variant="outlined"
                    disabled={currentState.listLoading || currentState.actionsLoading}
                    onChange={(e) => {

                        let val = e.target.value;
                        if (val.length > 2) {
                            // set 1 sec delay
                            clearTimeout(searchTimer);

                            searchTimer = setTimeout(() => dispatch(actions.searchProducts(val)), 1000);
                        } else if ( val.length === 0 ) {
                            // set 1 sec delay
                            clearTimeout(searchTimer);

                            searchTimer = setTimeout(() => dispatch(actions.searchProducts(val)), 1000);
                        } 

                    }}
                />
            </div>
            <div className={classes.onLoadingWrapper} hidden={!currentState.listLoading && !currentState.actionsLoading}>
                <div className="spinner spinner-primary spinner-left"></div> <label className={classes.loadingLabel}>Loading ...</label>
            </div>
            <TableContainer component={Paper} className={classes.root} hidden={currentState.listLoading || currentState.actionsLoading}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.thead} align="center" hidden={!bulkSelect}>
                                <label className="checkbox">
                                    <input type="checkbox" name="Checkboxes1" onChange={(e) => {requestSelectAll(e.target.checked)}} />
                                    <span></span>
                                </label>
                            </TableCell>
                            {TABLE_HEADER.map((header) => {
                                switch (header.name) {
                                    case "name":
                                    case "short_description":
                                    case "sku":
                                    case "backordered":
                                        return <TableCell onClick={() => requestSort(header.name)} className={classes.thead} key={header.name} align="left">{header.title}</TableCell>;
                                    case "actions":
                                        return <TableCell className={classes.actionColumn} key={header.name} align="center">{header.title}</TableCell>;
                                    default:
                                        return <TableCell onClick={() => requestSort(header.name)} className={classes.thead} key={header.name} align="right">{header.title}</TableCell>;
                                }

                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                if (row !== undefined) {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell className={classes.td} align="center" hidden={!bulkSelect}>
                                                <label className="checkbox">
                                                    <input type="checkbox" name="Checkboxes1" checked={row.selected} onChange={(e) => { requestItemSelectedUpdate(row.id, e.target.checked); }} />
                                                    <span></span>
                                                </label>
                                            </TableCell>
                                            <TableCell className={classes.td} align="left">{row.name}</TableCell>
                                            <TableCell className={classes.td} align="left">{row.short_description}</TableCell>
                                            <TableCell className={classes.td} align="left">{row.sku}</TableCell>
                                            <TableCell className={classes.td} align="right">{row.regular_price}</TableCell>
                                            <TableCell className={classes.td} align="right">{row.sale_price}</TableCell>
                                            <TableCell className={classes.td} align="right">{row.stock_quantity != null ? row.stock_quantity : 0}</TableCell>
                                            <TableCell className={classes.td} align="right">{row.stock_status}</TableCell>
                                            <TableCell className={classes.td} align="left">{row.backordered}</TableCell>
                                            <TableCell className={classes.oneLineText, classes.td} align="right">{row.date_created_gmt}</TableCell>
                                            <TableCell className={classes.oneLineText, classes.td} align="right">{row.date_modified_gmt}</TableCell>
                                            <TableCell className={classes.oneLineText, classes.td} align="center">
                                                <OverlayTrigger
                                                    overlay={<Tooltip id="products-edit-tooltip">Edit product</Tooltip>}
                                                >
                                                    <a
                                                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                                        onClick={() => gotoEditPage(row.id)}
                                                    >
                                                        <span className="svg-icon svg-icon-md svg-icon-primary">
                                                            <SVG
                                                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                                            />
                                                        </span>
                                                    </a>
                                                </OverlayTrigger>
                                                {` `}
                                                <OverlayTrigger
                                                    overlay={<Tooltip id="products-delete-tooltip">Delete product</Tooltip>}
                                                >
                                                    <a
                                                        className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                                        onClick={() => handleOpenDeleteModal(row.id, row.sku)}
                                                    >
                                                        <span className="svg-icon svg-icon-md svg-icon-danger">
                                                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                        </span>
                                                    </a>
                                                </OverlayTrigger>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            })}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableContainer>
            {/* Single Delete */}
            <Dialog
                open={open}
                onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={classes.onLoadingDialogWrapper} hidden={!currentState.listLoading && !currentState.actionsLoading}>
                    <div className="spinner spinner-primary spinner-left"></div> <label className={classes.loadingLabel}>Deleting product ...</label>
                </div>
                <DialogTitle id="alert-dialog-title" hidden={currentState.listLoading || currentState.actionsLoading} >{"Product Delete"}</DialogTitle>
                <DialogContent hidden={currentState.listLoading || currentState.actionsLoading}>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to permanently delete this product <label className={classes.bold}>{deleteProduct.sku}</label> ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions hidden={currentState.listLoading || currentState.actionsLoading}>
                    <Button onClick={handleCloseDeleteModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={requestToDelete} color="inherit" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Batch delete */}
            <Dialog
                open={openBatchDeleteModal}
                onClose={handleCloseBatchDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={classes.onLoadingDialogWrapper} hidden={!currentState.listLoading && !currentState.actionsLoading}>
                    <div className="spinner spinner-primary spinner-left"></div> <label className={classes.loadingLabel}>Deleting product ...</label>
                </div>
                <DialogTitle id="alert-dialog-title" hidden={currentState.listLoading || currentState.actionsLoading} >{"Product Delete"}</DialogTitle>
                <DialogContent hidden={currentState.listLoading || currentState.actionsLoading}>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to permanently delete all this product ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions hidden={currentState.listLoading || currentState.actionsLoading}>
                    <Button onClick={handleCloseBatchDeleteModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={requestToBatchDelete} color="inherit" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>           

        </>
    );
};