import dateFormat from "dateformat";
export function createData(
    id,
    name,
    description,
    display,
    menu_order,
    count,
    visibility,
    date_created,
    date_modified) {

    const regex = /(<([^>]+)>)/ig;

    let categoryName = name.replace(regex, '');
    let categoryDescription = description.replace(regex, '');
    let dateCreated = dateFormat(date_created, 'yyyy-mm-dd HH:MM:ss');
    let dateModified = dateFormat(date_modified, 'yyyy-mm-dd HH:MM:ss');

    return {
        id,
        categoryName,
        categoryDescription,
        display,
        menu_order,
        count,
        visibility,
        dateCreated,
        dateModified
    };
}

export const TABLE_HEADER = [
        {
            name : "categoryName",
            title : "Name"
        },
        {
            name : "categoryDescription",
            title : "Description"
        },
        {
            name : "display",
            title : "Display"
        },
        {
            name : "menu_order",
            title : "Menu order"
        },
        {
            name : "count",
            title : "Count"
        },
        {
            name : "visibility",
            title : "Visibility"
        },
        {
            name : "dateCreated",
            title : "Created"
        },
        {
            name : "dateModified",
            title : "Modified"
        },
        {
            name : "actions",
            title : "Actions"
        },
        
    ];