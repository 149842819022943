import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "./../../../../_metronic/_partials/controls";
import { forEach } from "lodash";
import * as actions from "../_redux/tenant-attributes/TenantAttributeActions";
import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers";
// import Switch from '@material-ui/core/Switch';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//     root: {
//         '& .MuiTextField-root': {
//             margin: theme.spacing(1),
//             width: '25ch',
//         },
//     }
// }));
// Validation schema
const EditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Model is required"),
    description: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 500 symbols")
});

export default function AdvertisingBasicInfo({
    tenantAttribute,
    save,
    btnRef,
    btnRefReset
}) {
    // const classes = useStyles();
    const dispatch = useDispatch();
    const regex = /(<([^>]+)>)/ig;
    let initData = { ...tenantAttribute };
    initData.description = initData.description.replace(regex, '');
    forEach(initData, (value, key) => {
        if (value === undefined || value === null) {
            initData[key] = "";
        }
    });
    // const history = useHistory();
    const [open, setOpen] = useState(false);
    const [status, setDialogStatus] = useState("Settings successfully saved!");
    function handleCloseModal() { setOpen(false); }
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initData}
                validationSchema={EditSchema}
                onSubmit={(values, { setState, setSubmitting }) => {

                    dispatch(actions.updateData(values)).then((response) => {
                        setDialogStatus("Settings successfully updated!")
                        setOpen(true);
                        setSubmitting(false);
                    });


                }}
            >
                {({
                    handleSubmit,
                    resetForm,
                    values,
                    setFieldValue }) => (
                    <>
                        <Form className="form form-label-right">

                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        name="name"
                                        component={Input}
                                        placeholder="Advertiser name"
                                        label="Advertiser Name"
                                    />
                                </div>
                                <div className="col-lg-8">
                                    <Field
                                        name="logo_url"
                                        component={Input}
                                        placeholder="Logo Link"
                                        label="Logo Link"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <Field
                                        name="description"
                                        component={Input}
                                        placeholder="Description"
                                        label="Description"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        name="primary_light_color"
                                        component={Input}
                                        placeholder="Primary Light HEX Color"
                                        label="Primary Light Color"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        name="primary_main_color"
                                        component={Input}
                                        placeholder="Primary Main HEX Color"
                                        label="Primary Main Color"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        name="primary_dark_color"
                                        component={Input}
                                        placeholder="Primary Dark HEX Color"
                                        label="Primary Dark Color"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        name="secondary_light_color"
                                        component={Input}
                                        placeholder="Secondary Light HEX Color"
                                        label="Secondary Light Color"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        name="secondary_main_color"
                                        component={Input}
                                        placeholder="Secondary Main HEX Color"
                                        label="Secondary Main Color"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        name="secondary_dark_color"
                                        component={Input}
                                        placeholder="Secondary Dark HEX Color"
                                        label="Secondary Dark Color"
                                    />
                                </div>                                
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        name="app_name"
                                        component={Input}
                                        placeholder="Mobile App Name"
                                        label="Mobile App Name"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        name="app_logo_url"
                                        component={Input}
                                        placeholder="Mobile App Logo Url"
                                        label="Mobile App Logo Url"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        name="apk_link"
                                        component={Input}
                                        placeholder="APK Link"
                                        label="APK Link"
                                    />
                                </div>
                                
                            </div>
                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                // Reset Button
                                type="button"
                                style={{ display: "none" }}
                                ref={btnRefReset}
                                onClick={resetForm}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>

            <Dialog
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="textPrimary">
                        <span className="svg-icon svg-icon-md svg-icon-primary" >
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                            />
                        </span>
                        {status}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );


}