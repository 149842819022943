import React, { useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "./../../../../_metronic/_partials/controls";
import { forEach } from "lodash";
import * as actions from "../_redux/settings/SettingsActions";
import { useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    app_logo: {
        width: '200px',
        height: '200px',
        border: '1px solid #000' 
    },
}) );

// Validation schema
const EditSchema = Yup.object().shape({
    app_display_name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Model is required")
});

export default function SettingsBasicInfo({
    mobileAppSettings,
    btnRef,
    btnRefReset
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    let initData = { ...mobileAppSettings };
    forEach(initData, (value, key) => {
        if (value === undefined || value === null) {
            initData[key] = "";
        }
    });
    const [open, setOpen] = useState(false);
    const [status, setDialogStatus] = useState("Settings successfully updated!");
    function handleCloseModal() { setOpen(false); }
    const img = useRef([]);
    const handlePhoto = (e) => {
        var reader = new FileReader();
        reader.readAsDataURL(e.currentTarget.files[0]);
        let fileName = e.currentTarget.files[0].name;
        reader.onload = (inp) => {
            img.current.push({
                file: reader.result,
                name: fileName
            });
        };

        reader.onerror = (error) => {
            img.current = [];
        };
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initData}
                validationSchema={EditSchema}
                onSubmit={(values, {setState, setSubmitting}) => {
                    values.img = img.current
                    dispatch(actions.updateData(values)).then((response) => {
                        setDialogStatus("Settings successfully updated!")
                        setOpen(true);
                        setSubmitting(false);
                    });

                }}
            >
                {({
                    handleSubmit,
                    resetForm,
                    values,
                    setFieldValue }) => (
                    <>
                        <Form className="form form-label-right">

                            <div className="form-group row">
                                <div className="col-lg-6">
                                    <Field
                                        name="tenant_id"
                                        component={Input}
                                        placeholder="Tenant ID"
                                        label="Tenant ID"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-6">
                                    <Field
                                        name="background_color"
                                        component={Input}
                                        placeholder="Background Color"
                                        label="Background Color"
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <Field
                                        name="app_display_name"
                                        component={Input}
                                        placeholder="App Display Name"
                                        label="App Display Name"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-6">
                                    <Field
                                        name="photo"
                                        component={Input}
                                        type='file'
                                        accept="image/png,image/jpeg"
                                        placeholder="App Logo"
                                        label="App Logo"
                                        onChange={handlePhoto}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <Field
                                        name="default_language"
                                        component={Input}
                                        placeholder="Default Language"
                                        label="Default Language"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-6">
                                    <img className={classes.app_logo}  src = {values.logo_url}/>
                                </div>
                            </div>
                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                // Reset Button
                                type="button"
                                style={{ display: "none" }}
                                ref={btnRefReset}
                                onClick={resetForm}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>

            <Dialog
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="textPrimary">
                        <span className="svg-icon svg-icon-md svg-icon-primary" >
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                            />
                        </span>
                        {status}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );


}