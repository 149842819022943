// update variable advertisingSetting and advertisingSettings
import {createSlice} from "@reduxjs/toolkit";

const initialDataState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  advertisingSettings: [],
  advertisingSettingForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const advertisingSettingSlice = createSlice({
  name: "advertisingSettings",
  initialState: initialDataState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    
    dataFetched: (state, action) => {
      state.actionsLoading = false;
      state.advertisingSettingForEdit = action.payload.setting;
      state.error = null;
    },
    
    collectionFetched: (state, action) => {
      
      const { totalCount, settings } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.advertisingSettings = settings;
      state.totalCount = totalCount;
    },
    
    dataCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.advertisingSettings.push(action.payload.setting);
    },
    
    dataUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { setting } = action.payload;
      state.advertisingSettings.forEach( (entity, key) => {
        if ( entity.id === setting.id ) {
          state.advertisingSettings[key] = {...setting};
        }
      } );
    },
    
    dataDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { id } = action.payload;
      state.advertisingSettings.forEach( (entity, key) => {
        if ( entity.id === id ) {
            delete state.advertisingSettings[key];
        }
      } );
    },
    
    collectionDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.advertisingSettings = state.advertisingSettings.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    
    collectionsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.advertisingSettings = state.advertisingSettings.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    
    dataReset: (state) => {
      state.advertisingSettingForEdit = null;
    },
    
  }
});
