import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { productSlice } from "../app/modules/Products/_redux/productSlice";
import { categorySlice } from "../app/modules/Settings/_redux/categories/categoriesSlice";
import { paymentSettingsSlice } from "../app/modules/Settings/_redux/payment_settings/paymentSettingsSlice";
import { zoneSlice } from "../app/modules/Settings/_redux/shipping-zones/shippingZoneSlice";
import { shippingMethodSlice } from "../app/modules/Settings/_redux/shipping-methods/shippingMethodSlice";
import { shippingLocationSlice } from "../app/modules/Settings/_redux/shipping-locations/shippingLocationSlice";
import { countrySlice } from "../app/modules/Settings/_redux/countries/countriesSlice";
import { zoneMethodSlice } from "../app/modules/Settings/_redux/shipping-zone-methods/shippingZoneMethodSlice";
import { advertisingSettingSlice } from "../app/modules/Settings/_redux/advertising/AdvertisingSlice";
import { tenantAttributeSlice } from "../app/modules/Settings/_redux/tenant-attributes/TenantAttributeSlice";
import { paymentHistorySlice } from "../app/modules/PaymentHistory/_redux/PaymentHistorySlice";
import { accountStatusSlice } from "../app/modules/Auth/_redux/accountStatusSlice";
import { mobileAppSettingsSlice } from "../app/modules/MobileApplications/_redux/settings/SettingsSlice";
import { releasesSlice } from "../app/modules/MobileApplications/_redux/releases/ReleasesSlice";

// import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
// import {productsSlice} from "../app/modules/ECommerce/_redux/productsSlice";
// import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  products: productSlice.reducer,
  categories: categorySlice.reducer,
  payment_settings: paymentSettingsSlice.reducer,
  zones: zoneSlice.reducer,
  locations: shippingLocationSlice.reducer,
  methods: shippingMethodSlice.reducer,
  countries: countrySlice.reducer,
  zoneMethods: zoneMethodSlice.reducer,
  advertisingSettings: advertisingSettingSlice.reducer,
  tenantAttribute: tenantAttributeSlice.reducer,
  payment_history: paymentHistorySlice.reducer,
  accountStatus: accountStatusSlice.reducer,
  mobileAppSettings: mobileAppSettingsSlice.reducer,
  releases: releasesSlice.reducer

  // customers: customersSlice.reducer,
  // products: productsSlice.reducer,
  // remarks: remarksSlice.reducer,
  // specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
