import * as requestFromServer from "./productsCrud";
import { productSlice, callTypes } from "./productSlice";

const { actions } = productSlice;

export const fetchProducts = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .fetchProducts()
        .then(response => {
            const { totalCount, products } = response.data;
            dispatch(actions.productsFetched({ totalCount, products }));
        })
        .catch(error => {
            error.clientMessage = "Can't find products";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const findProduct = (id) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .findProduct(id)
        .then(response => {
            delete response.data.product.images;
            delete response.data.product.dimensions;
            const { product } = response.data;
            dispatch(actions.productFetched({ product }));
        })
        .catch(error => {
            error.clientMessage = "Can't find product";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createProduct = (values) => dispatch => {

    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .createProduct(values)
        .then( response => {
            const { product } = response.data;
            dispatch(actions.productCreated({ product }));
        })
        .catch( error => {
            error.clientMessage = "Can't save product";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const updateProduct = (id, values) => dispatch => {

    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .updateProduct(id, values)
        .then( response => {
            const { product } = response.data;
            dispatch(actions.productCreated({ product }));
        })
        .catch( error => {
            error.clientMessage = "Can't update product";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const deleteProduct = (id) => dispatch => {

    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .deleteProduct(id)
        .then( response => {
            const { product } = response.data;
            dispatch(actions.productDeleted({ product }));
        })
        .catch( error => {
            error.clientMessage = "Can't delete product";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const deleteProducts = (ids) => dispatch => {

    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .deleteProducts(ids)
        .then( response => {
            const { products } = response.data;
            dispatch(actions.productsDeleted({ products }));
        })
        .catch( error => {
            error.clientMessage = "Can't delete products";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const productReset = () => {
    return actions.productReset();
}

export const productSetSelected = (item) => {
    return actions.productSetSelected();
}

export const searchProducts = (keyword) => dispatch => {
    dispatch( actions.startCall({callTypes: callTypes.list} ));
    return requestFromServer
        .searchProducts(keyword)
        .then(response => {
            const { totalCount, products } = response.data;
            dispatch(actions.productsFetched({ totalCount, products }));
        })
        .catch(error => {
            error.clientMessage = "Can't find products";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });

}

