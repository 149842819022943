import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "./../../../../_metronic/_partials/controls";
import { forEach } from "lodash";
import * as actions from "../_redux/payment_settings/paymentSettingsActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers";
// import { CATEGORY_DISPLAY } from "./PaymentSettingUIHelper";
import Switch from '@material-ui/core/Switch';

const EditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Model is required"),
    public_key: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 500 symbols"),
    private_secret: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")

});
export default function PaymentSettingBasicInfo({
    paymentSettings,
    save,
    btnRef,
    btnRefReset
}) {
    const dispatch = useDispatch();
    const regex = /(<([^>]+)>)/ig;    
    let initData  = {...paymentSettings};
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [status, setDialogStatus] = useState("Payment Settings successfully saved!");
    function handleCloseModal() { setOpen(false); history.push("/settings/payment_settings"); }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initData}
                validationSchema={EditSchema}
                onSubmit={(values, {setState, setSubmitting}) => {
                    
                    if (values.id) {
                        dispatch(actions.updatePaymentSettings(values.id, values)).then((response)=>{
                            setDialogStatus("Payment Settings successfully updated!")
                            setOpen(true);
                            setSubmitting(false);
                        });
                    }
                    else{
                        
                        dispatch(actions.createPaymentSettings(values)).then((response)=>{
                            setOpen(true);
                            setSubmitting(false);
                        });
                    }
                    
                    
                }}
            >
                {({ 
                    handleSubmit, 
                    resetForm, 
                    values,
                    setFieldValue}) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <Field
                                        name="name"
                                        component={Input}
                                        placeholder="Payment Gateway Name"
                                        label="Payment Gateway Name"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <Field
                                        name="public_key"
                                        component={Input}
                                        placeholder="Publishable Key"
                                        label="Publishable Key"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <Field
                                        name="private_secret"
                                        component={Input}
                                        placeholder="Private Secret Key"
                                        label="Private Secret Key"
                                    />
                                </div>
                            </div>
                            
                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                // Reset Button
                                type="button"
                                style={{ display: "none" }}
                                ref={btnRefReset}
                                onClick={resetForm}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>

            <Dialog
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="textPrimary">
                        <span className="svg-icon svg-icon-md svg-icon-primary" >
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                            />
                        </span>
                        {status}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Ok
                    </Button>                    
                </DialogActions>
            </Dialog>
        </>
    );
}