import dateFormat from "dateformat";
export function createData(
    selected,
    id,
    name,
    short_description,
    sku,
    regular_price,
    sale_price,
    stock_quantity,
    stock_status,
    backordered,
    date_created,
    date_modified) {

    const regex = /(<([^>]+)>)/ig;

    let productName = name.replace(regex, '');
    let productShortDescription = short_description.replace(regex, '');
    let dateCreated = dateFormat(date_created, 'yyyy-mm-dd HH:MM:ss');
    let dateModified = dateFormat(date_modified, 'yyyy-mm-dd HH:MM:ss');

    return {
        selected,
        id,
        productName,
        productShortDescription,
        sku,
        regular_price,
        sale_price,
        stock_quantity,
        stock_status,
        backordered,
        dateCreated,
        dateModified
    };
}

export const TABLE_HEADER = [
        {
            name : "name",
            title : "Name"
        },
        {
            name : "short_description",
            title : "Short Description"
        },
        {
            name : "sku",
            title : "SKU"
        },
        {
            name : "regular_price",
            title : "Regular Price"
        },
        {
            name : "sale_price",
            title : "Sale Price"
        },
        {
            name : "stock_quantity",
            title : "Stock Qty"
        },
        {
            name : "stock_status",
            title : "Stock Status"
        },
        {
            name : "backordered",
            title : "Backordered"
        },
        {
            name : "date_created_gmt",
            title : "Created"
        },
        {
            name : "date_modified_gmt",
            title : "Modified"
        },
        {
            name : "actions",
            title : "Actions"
        },
        
    ];