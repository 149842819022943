import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "./../../../../_metronic/_partials/controls";
import * as actions from "../_redux/categories/categoriesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CategoryBasicInfo from "./CategoriesBasicInfo";
export function CategoryEdit({
    match: {
        params: { id }
    }
}) {
    const history = useHistory();
    const [title, setTitle]  = useState("");
    const [tab, setTab] = useState("basic");
    const gotoCategoriesPage = () => {
        // Reset
        dispatch(actions.categoryReset());
        history.push("/settings/categories");
    }
    const btnRef = useRef();  
    const btnRefReset = useRef();
    const dispatch = useDispatch();
    // Fetch Data
    const { actionsLoading, categoryForEdit } = useSelector(
        (state) => ({ 
            actionsLoading: state.categories.actionsLoading,
            categoryForEdit: state.categories.categoryForEdit,
        }),
        shallowEqual
    );
    let initData = {
        "id" : undefined,
        "name": "",
        "description": "",
        "menu_order": "",
        "display": "",
        "visibility" : true
    };

    const resetForm = () => {
        // Reset
        if (btnRefReset && btnRefReset.current) {
            btnRefReset.current.click();
        }
    }

    const save = () => {
        if (btnRef && btnRef.current) {
            btnRef.current.click();
        }
    };

    
    useEffect(() => {
        
        if (id !== undefined) {
            dispatch(actions.findCategory(id));
        }
        
    }, [id, dispatch]);

    useEffect(() => {
        let _title = (id !== undefined) ? "" : "New Category";
        if ( categoryForEdit )
        {
            _title = `Edit Category - `;
            _title += (!actionsLoading && categoryForEdit ) ? `${categoryForEdit.name}` : ``;
        }
        setTitle(_title);
        
    }, [categoryForEdit, id]);
    

    return (
        <Card>
            <CardHeader title={title}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => gotoCategoriesPage()}
                    >
                        Back
                    </button>
                    {`  `}
                    <button
                        type="button"
                        className="btn btn-light ml-2"
                        onClick={() => resetForm()}
                    >
                        <i className="fa fa-redo"></i>
                        Reset
                    </button>
                    {`  `}
                    <button
                        type="submit"
                        className="btn btn-primary ml-2"
                        onClick={save}
                    >
                        Save
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                    <li className="nav-item" onClick={()=>{setTab("basic")}}>
                        <a
                            className={`nav-link ${tab === "basic" && "active"}`}
                            data-toggle = "tab"
                            role = "tab"
                            aria-selected={(tab==="basic").toString()}
                            >
                            Basic Info
                        </a>
                    </li>
                </ul>
                <div className="mt-5">
                    {tab === "basic" && (
                        
                        <CategoryBasicInfo 
                            category={ categoryForEdit || initData } 
                            save={save}
                            btnRef={btnRef}
                            btnRefReset ={btnRefReset}
                            />
                    )}
                </div>
                
            </CardBody>
        </Card>
    );
}