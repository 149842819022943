import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/payment_settings/paymentSettingsActions";
import { createData, TABLE_HEADER } from './../_redux/payment_settings/PaymentSettings';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar
} from "./../../../../_metronic/_partials/controls";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from '@material-ui/core/Paper';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    keys : {
        wordBreak: "break-word",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: "16px", /* fallback */
        maxHeight: "32px", /* fallback */
        maxWidth: "320px"
    }
}));

export function PaymentSettings() {
    let rows = [];
    const classes = useStyles();
    const history = useHistory();
    const { currentState } = useSelector((state) => ({ currentState: state.payment_settings }), shallowEqual);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = useState(false);
    const [deleteItem, setToDeleteItem] = useState({ id: 0, name: ""});


    // Pagination
    const handleChangePage = (event, newPage) => { setPage(newPage); };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // End of Pagination

    // Popullate table
    const dispatch = useDispatch();
    useEffect(() => { dispatch(actions.fetchPaymentSettings()); }, []);
    if (!currentState.listLoading && currentState.paymentSettings) {
        rows = [];
        currentState.paymentSettings.forEach(payment_settings => {
            rows.push(
                createData(
                    payment_settings.id,
                    payment_settings.name,
                    payment_settings.public_key,
                    payment_settings.private_secret,
                    payment_settings.date_created,
                    payment_settings.date_modified,
                    payment_settings.visible
                )
            );
        });
    }
    const gotoEditPage = (id) => { history.push('/settings/payment_settings/' + id + '/edit'); }
    const newPaymentSettings = () => { history.push("/settings/payment_settings/new"); }
    const showSecret = (id) => {
        dispatch(actions.showSecret(id));
        console.log('New', currentState.paymentSettings)
        
    }
    
    function handleOpenDeleteModal(id, name) { setToDeleteItem({id, name}); setOpen(true); } 
    function handleCloseDeleteModal() { setOpen(false); }
    function requestToDelete() { 
        dispatch(actions.deletePaymentSettings(deleteItem.id)).then(() => {
            dispatch(actions.fetchPaymentSettings());
            handleCloseDeleteModal();
            setPage(0);
        })
    }

    return (
        <Card>
            <CardHeader title="Payment Settings">
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={newPaymentSettings}
                    >
                        New Payment Settings
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <TableContainer component={Paper} className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {TABLE_HEADER.map((header) => {
                                    switch (header.name) {
                                        case "dateCreated":
                                        case "dateModified":
                                            return <TableCell className={classes.thead} key={header.name} align="right">{header.title}</TableCell>;
                                        case "actions":
                                            return <TableCell className={classes.thead} key={header.name} align="center">{header.title}</TableCell>;
                                        default:
                                            return <TableCell className={classes.thead} key={header.name} align="left">{header.title}</TableCell>;
                                    }

                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell className={classes.td} align="left">{row.name}</TableCell>
                                        <TableCell className={classes.td, classes.keys} align="left">{row.public_key}</TableCell>
                                        <TableCell className={classes.td, classes.keys} align="left">{(row.visible) ? row.private_secret: '...'}</TableCell>
                                        <TableCell className={classes.oneLineText, classes.td} align="right">{row.dateCreated}</TableCell>
                                        <TableCell className={classes.oneLineText, classes.td} align="right">{row.dateModified}</TableCell>
                                        <TableCell className={classes.oneLineText, classes.td} align="center">
                                            <OverlayTrigger
                                                overlay={<Tooltip id="visible-tooltip">Show Secret</Tooltip>}
                                            >
                                                <a
                                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                                    onClick={() => showSecret(row.id)}
                                                >
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
                                                        />
                                                    </span>
                                                </a>
                                            </OverlayTrigger>
                                            {` `}
                                            <OverlayTrigger
                                                overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}
                                            >
                                                <a
                                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                                    onClick={() => gotoEditPage(row.id)}
                                                >
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                                        />
                                                    </span>
                                                </a>
                                            </OverlayTrigger>
                                            {` `}
                                            <OverlayTrigger
                                                overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}
                                            >
                                                <a
                                                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                                    onClick={() => handleOpenDeleteModal(row.id, row.name)}
                                                >
                                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                    </span>
                                                </a>
                                            </OverlayTrigger>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
                <Dialog
                    open={open}
                    onClose={handleCloseDeleteModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Payment Settings Delete"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure to permanently delete this settings - <label className={classes.bold}>{ deleteItem.name }</label> ?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteModal} color="primary">
                            Cancel
                    </Button>
                        <Button onClick={requestToDelete} color="inherit" autoFocus>
                            Delete
                    </Button>
                    </DialogActions>
                </Dialog>

            </CardBody>
        </Card>
    )
}