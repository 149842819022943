import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  payment_history: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const paymentHistorySlice = createSlice({
  name: "payment_history",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    
    paymentHistoryFetched: (state, action) => {
      
      const { totalCount, payment_history } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.payment_history = payment_history;
      state.totalCount = totalCount;
    }
    
  }
});
