import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  products: null,
  productForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const productSlice = createSlice({
  name: "products",
  initialState: initialProductsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById
    productFetched: (state, action) => {
      state.actionsLoading = false;
      state.productForEdit = action.payload.product;
      state.error = null;
    },
    // findProducts
    productsFetched: (state, action) => {
      const { totalCount, products } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.products = products;
      state.totalCount = totalCount;
    },
    // createProduct
    productCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.products.push(action.payload.product);
    },
    // updateProduct
    productUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.products.push(action.payload.product);
    },
    // deleteProduct
    productDeleted: (state, action) => {
      const { product } = action.payload;
      state.products.forEach((entity, key) => {
        if (entity.id === product.id) {
          delete state.products[key];
        }
      });
      state.actionsLoading = false;
      state.error = null;

    },
    // deleteProducts
    productsDeleted: (state, action) => {
      const { products } = action.payload;
      state.products.forEach((entity, key) => {

        products.forEach(( product, index ) => {
          if (entity.id === product.id) {
            delete state.products[key];
          }
        });
        
      });
      state.actionsLoading = false;
      state.error = null;
    },
    // productsUpdateState
    productsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.products = state.products.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    // Reset Products
    productReset: (state) => {
      state.productForEdit = null;
    },
    productSetSelected: (state, item) => {
      state.products.forEach((entity, key) => {
        if (entity.id === item.id) {
          state.products[key].selected = !state.products[key].selected;
        }
      });
    }
  }
});
