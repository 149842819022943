import * as requestFromServer from "../../../../Redux/ReduxSimpleCrud";
import { paymentSettingsSlice, callTypes } from "./paymentSettingsSlice";

const BASE_URL = `${process.env.REACT_APP_API_URL}/payment/settings`;
const { actions } = paymentSettingsSlice;

export const fetchPaymentSettings = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .fetchData(BASE_URL)
        .then(response => {
            const { totalCount, payment_settings } = response.data;
            dispatch(actions.paymentSettingsFetched({ totalCount, payment_settings }));
        })
        .catch(error => {
            error.clientMessage = "Can't find payment settings";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const findPaymentSettings = (id) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .findData(BASE_URL + "/web", id)
        .then(response => {
            const { payment_settings } = response.data;
            dispatch(actions.paymentSettingsFind({ payment_settings }));
            console.log(payment_settings);
        })
        .catch(error => {
            error.clientMessage = "Can't find payment settings";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createPaymentSettings = (values) => dispatch => {
    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .createData(BASE_URL, values)
        .then( response => {
            const { payment_settings } = response.data;
            dispatch(actions.paymentSettingsCreated({ payment_settings }));
        })
        .catch( error => {
            error.clientMessage = "Can't save payment settings";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const updatePaymentSettings = (id, values) => dispatch => {

    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .updateData(BASE_URL, id, values)
        .then( response => {
            const { payment_settings } = response.data;
            dispatch(actions.paymentSettingsUpdated({ payment_settings }));
        })
        .catch( error => {
            error.clientMessage = "Can't update payment settings";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const deletePaymentSettings = (id) => dispatch => {

    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .deleteData(BASE_URL, id, true)
        .then( response => {
            const { payment_settings } = response.data;
            dispatch(actions.paymentSettingsDeleted({ payment_settings }));
        })
        .catch( error => {
            error.clientMessage = "Can't delete payment settings";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

export const showSecret = (id) => dispatch => {
    console.log(id);
    return dispatch(actions.showSecret(id));
}

export const paymentSettingsReset = () => {
    return actions.paymentSettingsReset();
}

