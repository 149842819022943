import * as requestFromServer from "./authCrud";
import { accountStatusSlice, callTypes } from "./accountStatusSlice";

const { actions } = accountStatusSlice;


export const checkIfActivated = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.actions }));
    return requestFromServer
        .checkIfActivated()
        .then(response => {
            const { status } = response.data;
            dispatch(actions.updateAccountStatus({ status }));
        })
        .catch(error => {
            error.clientMessage = "Can't find the records";
            dispatch(actions.catchError({ error, callType: callTypes.actions }));
        });
};