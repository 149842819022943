// update variable zone and zones
import {createSlice} from "@reduxjs/toolkit";

const initialDataState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  zones: null,
  zoneForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const zoneSlice = createSlice({
  name: "zones",
  initialState: initialDataState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    
    dataFetched: (state, action) => {
      state.actionsLoading = false;
      state.zoneForEdit = action.payload.zone;
      state.error = null;
    },
    
    collectionFetched: (state, action) => {
      
      const { totalCount, zones } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.zones = zones;
      state.totalCount = totalCount;
    },
    
    dataCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.zones.push(action.payload.zone);
    },
    
    dataUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { zone } = action.payload;
      state.zones.forEach( (entity, key) => {
        if ( entity.id === zone.id ) {
          state.zones[key] = {...zone};
        }
      } );
    },
    
    dataDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { zone } = action.payload;
      state.zones.forEach( (entity, key) => {
        if ( entity.id === zone.id ) {
            delete state.zones[key];
        }
      } );
    },
    
    collectionDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.zones = state.zones.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    
    collectionsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.zones = state.zones.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    
    dataReset: (state) => {
      state.zoneForEdit = null;
    },
    
  }
});
