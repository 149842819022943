// update variable _data and _collection
import {createSlice} from "@reduxjs/toolkit";

const initialDataState = {
  listLoading: false,
  actionsLoading: false,
  mobileAppSettings: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const mobileAppSettingsSlice = createSlice({
  name: "mobileAppSettings",
  initialState: initialDataState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    collectionFetched: (state, action) => {
      
      const { settings } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.mobileAppSettings = settings;
    },
    
    dataUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { settings } = action.payload;
      state.mobileAppSettings = settings;
    }
    
  }
});
