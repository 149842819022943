// update variable zoneMethod and zoneMethods
import {createSlice} from "@reduxjs/toolkit";

const initialDataState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  zoneMethods: [],
  zoneMethodForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const zoneMethodSlice = createSlice({
  name: "zoneMethods",
  initialState: initialDataState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    
    dataFetched: (state, action) => {
      state.actionsLoading = false;
      state.zoneMethodForEdit = action.payload.zoneMethod;
      state.error = null;
    },
    
    collectionFetched: (state, action) => {
      
      const { totalCount, methods } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.zoneMethods = methods;
      state.totalCount = totalCount;
    },
    
    dataCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.zoneMethods.push(action.payload.zoneMethod);
    },
    
    dataUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { zoneMethod } = action.payload;
      state.zoneMethods.forEach( (entity, key) => {
        if ( entity.id === zoneMethod.id ) {
          state.zoneMethods[key] = {...zoneMethod};
        }
      } );
    },
    
    dataDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { zoneMethod } = action.payload;
      state.zoneMethods.forEach( (entity, key) => {
        if ( entity.id === zoneMethod.id ) {
            delete state.zoneMethods[key];
        }
      } );
    },
    
    collectionDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.zoneMethods = state.zoneMethods.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    
    collectionsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.zoneMethods = state.zoneMethods.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    
    dataReset: (state) => {
      state.zoneMethodForEdit = null;
    },
    
  }
});
