import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
  actionsLoading: false,
  status: true,
  lastError: null
};
export const callTypes = {
  action: "action"
};

export const accountStatusSlice = createSlice({
  name: "accountStatus",
  initialState: initialProductsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById
    updateAccountStatus: (state, action) => {
      state.actionsLoading = false;
      state.status = action.payload.status;
      state.error = null;
    }
  }
});
