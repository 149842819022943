import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "./../../../../_metronic/_partials/controls";
import * as actions from "../_redux/tenant-attributes/TenantAttributeActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import TenantAttributeBasicInfo from "./TenantAttributeBasicInfo";
export function TenantAttributeEdit() {
    const [tab, setTab] = useState("basic");

    const btnRef = useRef();  
    const btnRefReset = useRef();
    const dispatch = useDispatch();
    // Fetch Data
    const { tenantAttribute } = useSelector(
        (state) => ({ 
            tenantAttribute: state.tenantAttribute.tenantAttribute,
        }),
        shallowEqual
    );
    let initData = {
        "name": "",
        "description": "",
        "logo_url": "",
        "primary_light_color": "",
        "primary_main_color": "",
        "primary_dark_color": "",
        "secondary_light_color": "",
        "secondary_main_color": "",
        "secondary_dark_color": "",
        "app_name": "",
        "app_logo_url": "",
        "apk_link": ""
    };

    const resetForm = () => {
        // Reset
        if (btnRefReset && btnRefReset.current) {
            btnRefReset.current.click();
        }
    }

    const save = () => {
        if (btnRef && btnRef.current) {
            btnRef.current.click();
        }
    };

    
    useEffect(() => {
        
        dispatch(actions.fetchData());
        
    }, [dispatch]);
    

    return (
        <Card>
            <CardHeader title={"Attribute Settings"}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-light ml-2"
                        onClick={() => resetForm()}
                    >
                        <i className="fa fa-redo"></i>
                        Reset
                    </button>
                    {`  `}
                    <button
                        type="submit"
                        className="btn btn-primary ml-2"
                        onClick={save}
                    >
                        Save
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                    <li className="nav-item" onClick={()=>{setTab("basic")}}>
                        <a
                            className={`nav-link ${tab === "basic" && "active"}`}
                            data-toggle = "tab"
                            role = "tab"
                            aria-selected={(tab==="basic").toString()}
                            >
                        </a>
                    </li>
                </ul>
                <div className="mt-5">
                    {tab === "basic" && (
                        
                        <TenantAttributeBasicInfo 
                            tenantAttribute={ tenantAttribute || initData } 
                            save={save}
                            btnRef={btnRef}
                            btnRefReset ={btnRefReset}
                            />
                    )}
                </div>
                
            </CardBody>
        </Card>
    );
}