import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import * as actions from "../_redux/payment_settings/paymentSettingsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PaymentSettingBasicInfo from "./PaymentSettingBasicInfo";
export function PaymentSettingEdit({
    match: {
        params: { id }
    }
}) {
    const history = useHistory();
    const [title, setTitle]  = useState("");
    const [tab, setTab] = useState("basic");
    const gotoPaymentSettingPage = () => {
        // Reset
        dispatch(actions.paymentSettingsReset());
        history.push("/settings/payment_settings");
    }
    const btnRef = useRef();  
    const btnRefReset = useRef();
    const dispatch = useDispatch();
    // Fetch Data
    const { actionsLoading, paymentSettingsForEdit } = useSelector(
        (state) => ({ 
            actionsLoading: state.payment_settings.actionsLoading,
            paymentSettingsForEdit: state.payment_settings.paymentSettingsForEdit,
        }),
        shallowEqual
    );
    let initData = {
        "id" : undefined,
        "name" : "",
        "public_key" : "",
        "private_secret" : ""
    };
    const resetForm = () => {
        // Reset
        if (btnRefReset && btnRefReset.current) {
            btnRefReset.current.click();
        }
    }

    const save = () => {
        if (btnRef && btnRef.current) {
            btnRef.current.click();
        }
    };

    useEffect(() => {
        
        if (id !== undefined) {
            dispatch(actions.findPaymentSettings(id));
        }
        
    }, [id, dispatch]);

    useEffect(() => {
        let _title = (id !== undefined) ? "" : "New Payment Settings";
        if ( paymentSettingsForEdit )
        {
            _title = `Edit Payment Settings - `;
            _title += (!actionsLoading && paymentSettingsForEdit ) ? `${paymentSettingsForEdit.name}` : ``;
        }
        setTitle(_title);
        
    }, [paymentSettingsForEdit, id]);
    return (
        <Card>
            <CardHeader title={title}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => gotoPaymentSettingPage()}
                    >
                        Back
                    </button>
                    {`  `}
                    <button
                        type="button"
                        className="btn btn-light ml-2"
                        onClick={() => resetForm()}
                    >
                        <i className="fa fa-redo"></i>
                        Reset
                    </button>
                    {`  `}
                    <button
                        type="submit"
                        className="btn btn-primary ml-2"
                        onClick={save}
                    >
                        Save
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                    <li className="nav-item" onClick={()=>{setTab("basic")}}>
                        <a
                            className={`nav-link ${tab === "basic" && "active"}`}
                            data-toggle = "tab"
                            role = "tab"
                            aria-selected={(tab==="basic").toString()}
                            >
                            Basic Info
                        </a>
                    </li>
                </ul>
                <div className="mt-5">
                    {tab === "basic" && (
                        
                        <PaymentSettingBasicInfo 
                            paymentSettings={ paymentSettingsForEdit || initData } 
                            save={save}
                            btnRef={btnRef}
                            btnRefReset ={btnRefReset}
                            />
                    )}
                </div>
                
            </CardBody>
        </Card>
    );
    
}