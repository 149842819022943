// Source https://codesandbox.io/embed/table-sorting-example-ur2z9?fontsize=14&hidenavigation=1&theme=dark
import { update } from "lodash";
import React, { useState, useMemo } from "react";


export const useSortableData = (items, config = null, initSelectedItems = []) => {

    const [sortConfig, setSortConfig] = useState(config);

    const [selectedItems, setSelectedItems] = useState(initSelectedItems);

    const sortedItems = useMemo(() => {
        let sortableItems = [];
        if (items) {
            items.map((item, index) => {
                let pushItem = true;
                if (item === undefined) {
                    delete items[index];
                    pushItem = false;
                }
                if (pushItem) {

                    let tmpItem = { ...item };

                    if (selectedItems.indexOf(item.id + "") > -1) {

                        tmpItem.selected = true;

                    }

                    sortableItems.push(tmpItem);
                }

            });

            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {

                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }

                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }

                    return 0;

                });
            }


            return sortableItems;
        }
        else {
            return [];
        }

    }, [items, sortConfig, selectedItems]);

    const requestSort = (key) => {

        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }

        setSortConfig({ key, direction });

    };

    const requestItemSelectedUpdate = (id, checked) => {

        let temp = [...selectedItems];
        let found = false;
        let currIndex = null;

        id = id + "";

        temp.map((val, index) => {
            if (val === id) {
                found = true;
                currIndex = index;
            }
        });

        if (!checked && found) {
            temp.splice(currIndex, 1);
        }


        if (checked && !found) {
            temp.push(id);
        }
        setSelectedItems(temp);
    }

    const requestSelectAll = (isChecked) => {

        let temp = [];

        if (isChecked) {
            items.map((item, index) => {

                temp.push(item.id + "");

            });
        }

        setSelectedItems(temp);

    }

    return {
        items: sortedItems,
        requestSort,
        sortConfig,
        requestItemSelectedUpdate,
        requestSelectAll,
        bulkSelectedItems: selectedItems
    };

}