import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/admin_login?tenant_id=hn_` + window.location.hostname;
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/auth/register?tenant_id=hn_` + window.location.hostname;
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/customers/info?tenant_id=hn_` + window.location.hostname;
export const CHECK_IF_ACTIVATED_URL = `${process.env.REACT_APP_API_URL}/auth/check-account-status?tenant_id=hn_` + window.location.hostname;

export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password });
}

export function register(wp_hostname, consumer_key, consumer_secret) {
  return axios.post(REGISTER_URL, { wp_hostname, consumer_key, consumer_secret });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // let token = authToken;
  // if (typeof authToken === 'object')
  // {
  //   token = authToken.payload.user.payload.authToken;
  // }
  return axios.get(ME_URL);
}

export function checkIfActivated() {
  return axios.get(CHECK_IF_ACTIVATED_URL);
}
