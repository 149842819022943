import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/advertising/AdvertisingActions";
import { createData, TABLE_HEADER } from './../_redux/advertising/Advertising';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar
} from "./../../../../_metronic/_partials/controls";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from '@material-ui/core/Paper';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        boxShadow: 'none !important'
    },
    table: {
        minWidth: 650,
    },
    thead: {
        whiteSpace: 'nowrap',
        fontWeight: 600,
        color: '#B5B5C3',
        textTransform: 'uppercase',
        padding: '.75rem',
        fontSize: '.9rem !important',
        fontFamily: 'Poppins,Helvetica,"sans-serif',
        letterSpacing: '.1rem'
    },
    actionColumn: {
        whiteSpace: 'nowrap',
        fontWeight: 600,
        color: '#B5B5C3',
        textTransform: 'uppercase',
        padding: '.75rem',
        fontSize: '.9rem !important',
        fontFamily: 'Poppins,Helvetica,"sans-serif',
        letterSpacing: '.1rem',
        minWidth: '110px !important'
    },
    td: {
        padding: '.75rem',
        fontSize: '13px !important',
        fontWeight: '400',
        fontFamily: 'Poppins,Helvetica,"sans-serif'
    }
}));

let rows = [];
export function Advertising() {
    const classes = useStyles();
    const history = useHistory();
    const { currentState } = useSelector((state) => ({ currentState: state.advertisingSettings }), shallowEqual);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = useState(false);
    const [deleteItem, setToDeleteItem] = useState({ id: 0, name: ""});

    // Pagination
    const handleChangePage = (event, newPage) => { setPage(newPage); };
    const gotoEditPage = (id) => { dispatch(actions.dataReset()); history.push('/settings/advertising/' + id + '/edit'); }
    const newSettings = () => { dispatch(actions.dataReset()); history.push("/settings/advertising/new"); }
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    function handleOpenDeleteModal(id, name) { setToDeleteItem({id, name}); setOpen(true); } 
    function handleCloseDeleteModal() { setOpen(false); }
    function requestToDelete() { 
        dispatch(actions.deleteData(deleteItem.id)).then(() => {
            dispatch(actions.fetchData());
            handleCloseDeleteModal();
            setPage(0);
        })
    }

    const dispatch = useDispatch();
    useEffect(() => { dispatch(actions.fetchData()); }, []);
    
    if (!currentState.listLoading && currentState.advertisingSettings) {
        rows = [];
        currentState.advertisingSettings.forEach(setting => {
            rows.push(
                createData(
                    setting.id,
                    setting.name,
                    setting.description,
                    setting.category_name,
                    setting.script,
                    (setting.active) ? true : false,
                    setting.created_at,
                    setting.updated_at
                )
            );
        });
    }

    const handleChange = row => event => {
        let values = {
            id: row.id,
            active : event.target.checked
        }
        dispatch( actions.updateData(row.id, values) );
    };

    return (
        <Card>
            <CardHeader title="Advertisers">
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={newSettings}
                    >
                        New Advertiser
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <TableContainer component={Paper} className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {TABLE_HEADER.map((header) => {
                                    switch (header.name) {
                                        case "dateCreated":
                                        case "dateModified":
                                            return <TableCell className={classes.thead} key={header.name} align="right">{header.title}</TableCell>;
                                        case "actions":
                                        case "active":
                                            return <TableCell className={classes.thead} key={header.name} align="center">{header.title}</TableCell>;
                                        default:
                                            return <TableCell className={classes.thead} key={header.name} align="left">{header.title}</TableCell>;
                                    }

                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell className={classes.td} align="left">{row.name}</TableCell>
                                        <TableCell className={classes.td} align="left">{row.description}</TableCell>
                                        <TableCell className={classes.td} align="left">{row.category_name}</TableCell>
                                        <TableCell className={classes.td} align="center">
                                        <Switch
                                            checked={row.active}
                                            onChange={handleChange(row)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                        </TableCell>
                                        <TableCell className={classes.oneLineText, classes.td} align="right">{row.dateCreated}</TableCell>
                                        <TableCell className={classes.oneLineText, classes.td} align="right">{row.dateModified}</TableCell>
                                        <TableCell className={classes.oneLineText, classes.td} align="center">
                                            <OverlayTrigger
                                                overlay={<Tooltip id="edit-tooltip">Edit Settings</Tooltip>}
                                            >
                                                <a
                                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                                    onClick={() => gotoEditPage(row.id)}
                                                >
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                                        />
                                                    </span>
                                                </a>
                                            </OverlayTrigger>
                                            {` `}
                                            <OverlayTrigger
                                                overlay={<Tooltip id="delete-tooltip">Delete Settings</Tooltip>}
                                            >
                                                <a
                                                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                                    onClick={() => handleOpenDeleteModal(row.id, row.name)}
                                                >
                                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                    </span>
                                                </a>
                                            </OverlayTrigger>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
                <Dialog
                    open={open}
                    onClose={handleCloseDeleteModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Category Delete"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure to permanently delete this settings <label className={classes.bold}>{ deleteItem.name }</label> ?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteModal} color="primary">
                            Cancel
                    </Button>
                        <Button onClick={requestToDelete} color="inherit" autoFocus>
                            Delete
                    </Button>
                    </DialogActions>
                </Dialog>

            </CardBody>
        </Card>
        
    );
}