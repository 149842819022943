import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "./../../../../_metronic/_partials/controls";
import { forEach } from "lodash";
import * as actions from "../_redux/categories/categoriesActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers";
import { CATEGORY_DISPLAY } from "./CategoriesUIHelper";
import Switch from '@material-ui/core/Switch';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//     visibility: {
//         paddingTop: '20px !important'
//     }
// }));
// Validation schema
const CategoryEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Model is required"),
    description: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 500 symbols"),
    display: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols"),
    menu_order: Yup.number()
        .min(0, "0 is minimum")
        .max(1000000, "1000000 is maximum")

});

export default function CategoryBasicInfo({
    category,
    save,
    btnRef,
    btnRefReset
}) {
    // const classes = useStyles();
    const dispatch = useDispatch();
    const regex = /(<([^>]+)>)/ig;    
    let initCategory  = {...category};
    initCategory.description = initCategory.description.replace(regex, '');
    forEach( initCategory, (value, key) => {
        if (value === undefined || value === null)
        {   
            initCategory[key] = "";
        }
    });
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [status, setDialogStatus] = useState("Category successfully saved!");
    function handleCloseModal() { setOpen(false); history.push("/settings/categories"); }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initCategory}
                validationSchema={CategoryEditSchema}
                onSubmit={(values, {setState, setSubmitting}) => {
                    
                    values.count = ( values.count === "" ) ? 0 : values.count;

                    if ( isNaN( Number(values.count) ) )
                    {
                        values.count = 0;
                    }
                    else
                    {
                        values.count = Number(values.count);
                    }

                    values.menu_order = Number(values.menu_order);
                    values.display = ( values.display === "" ) ? "default" : values.display;

                    let photo = document.getElementById("photo");
                    let file = photo.files[0];
                    
                    const fd = new FormData();

                    fd.append("name", values.name);
                    fd.append("description", values.description);
                    fd.append("display", values.display);
                    fd.append("menu_order", values.menu_order);
                    fd.append("visibility", values.visibility);
                    fd.append("photo", file, file.name);
                    fd.append("count", values.count );
                    

                    if (values.id) {
                        dispatch(actions.updateCategory(values.id, fd)).then((response)=>{
                            setDialogStatus("Category successfully updated!")
                            setOpen(true);
                            setSubmitting(false);
                        });
                    }
                    else{
                        
                        dispatch(actions.createCategory(fd)).then((response)=>{
                            setOpen(true);
                            setSubmitting(false);
                        });
                    }
                    
                    
                }}
            >
                {({ 
                    handleSubmit, 
                    resetForm, 
                    values,
                    setFieldValue}) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <input type="file" name="file" id="photo" accept="image/png, image/jpeg"/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        name="name"
                                        component={Input}
                                        placeholder="Category name"
                                        label="Category Name"
                                    />
                                </div>
                                <div className="col-lg-8">
                                    <Field
                                        name="description"
                                        component={Input}
                                        placeholder="Description"
                                        label="Description"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Select name = "display" label = "Stock Status">
                                        { CATEGORY_DISPLAY.map((display) => (
                                            <option key={display} value={display}>
                                                {display}
                                            </option>
                                        )) }
                                    </Select>
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        name="menu_order"
                                        component={Input}
                                        placeholder="Menu Order"
                                        label="Menu Order"
                                    />
                                </div>      
                                <div className="col-lg-4 visibility">
                                    <label>
                                        Visibility
                                    </label>
                                    <Switch
                                        name="visibility"
                                        value="true"
                                        checked={values.visibility}
                                        onChange={(event, checked) => {
                                            setFieldValue("visibility", checked);
                                        }}
                                    />
                                </div>
                            </div>
                            
                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                // Reset Button
                                type="button"
                                style={{ display: "none" }}
                                ref={btnRefReset}
                                onClick={resetForm}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>

            <Dialog
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="textPrimary">
                        <span className="svg-icon svg-icon-md svg-icon-primary" >
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                            />
                        </span>
                        {status}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Ok
                    </Button>                    
                </DialogActions>
            </Dialog>
        </>
    );


}