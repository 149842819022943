import React, { Suspense } from "react";
import { useSubheader } from "./../../../_metronic/layout";
import { ProductsCard } from "./ProductsCard";
import { ProductsEdit } from "./products-edit/ProductsEdit"
import { LayoutSplashScreen, ContentRoute } from "./../../../_metronic/layout";
import { Switch } from "react-router";

export const ProductsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Products");


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact={true} path="/products/new" component={ProductsEdit} />
        <ContentRoute exact={true} path="/products/:id/edit" component={ProductsEdit} />
        <ContentRoute exact={true} path="/products" component={ProductsCard} />
      </Switch>
    </Suspense>
  );
};
