import dateFormat from "dateformat";
export function createData(
    id,
    name,
    description,
    category_name,
    script,
    active,
    date_created,
    date_modified) {

    let dateCreated = dateFormat(date_created, 'yyyy-mm-dd HH:MM:ss');
    let dateModified = dateFormat(date_modified, 'yyyy-mm-dd HH:MM:ss');

    return {
        id,
        name,
        description,
        category_name,
        script,
        active,
        dateCreated,
        dateModified
    };
}

export const TABLE_HEADER = [
        {
            name : "name",
            title : "Name"
        },
        {
            name : "description",
            title : "Description"
        },
        {
            name : "category_name",
            title : "Category"
        },
        {
            name : "active",
            title : "Active"
        },
        {
            name : "dateCreated",
            title : "Created"
        },
        {
            name : "dateModified",
            title : "Modified"
        },
        {
            name : "actions",
            title : "Actions"
        },
        
    ];