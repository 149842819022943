import * as requestFromServer from "../../../../Redux/ReduxSimpleCrud";
import { mobileAppSettingsSlice, callTypes } from "./SettingsSlice";

const BASE_URL = `${process.env.REACT_APP_API_URL}/mobile-app/settings`;
const { actions } = mobileAppSettingsSlice;

export const fetchData = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .fetchData(BASE_URL)
        .then(response => {
            const { settings } = response.data;
            dispatch(actions.collectionFetched({ settings }));
        })
        .catch(error => {
            error.clientMessage = "Can't find settings";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updateData = (values) => dispatch => {

    dispatch(actions.startCall({callTypes: callTypes.action}));
    return requestFromServer
        .updateData(BASE_URL, "", values)
        .then( response => {
            const { settings } = response.data;
            dispatch(actions.dataUpdated({ settings }));
        })
        .catch( error => {
            error.clientMessage = "Can't update settings";
            dispatch(actions.catchError({ error, callTypes: callTypes.action }));
        })

};

