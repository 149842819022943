import {createSlice} from "@reduxjs/toolkit";

const initialReleasesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  releases: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const releasesSlice = createSlice({
  name: "releases",
  initialState: initialReleasesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    
    releasesFetched: (state, action) => {
      
      const { totalCount, releases } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.releases = releases;
      state.totalCount = totalCount;
    },
    
  }
});
