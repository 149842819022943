import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "./../../../_metronic/_partials/controls";
import PaymentHistoryTable from "./payment-history-table/PaymentHistoryTable";

export function PaymentHistoryCard() {
  
  return (
    <Card>
      <CardHeader title="Payments History">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
       
        <PaymentHistoryTable />
      </CardBody>
    </Card>
  );
}
