import dateFormat from "dateformat";
export function createData(
    id,
    name,
    public_key,
    private_secret,
    date_created,
    date_modified,
    visible) {

    let dateCreated = dateFormat(date_created, 'yyyy-mm-dd HH:MM:ss');
    let dateModified = dateFormat(date_modified, 'yyyy-mm-dd HH:MM:ss');

    return {
        id,
        name,
        public_key,
        private_secret,
        dateCreated,
        dateModified,
        visible
    };
}

export const TABLE_HEADER = [
        {
            name : "name",
            title : "Name"
        },
        {
            name : "public_key",
            title : "Publishable Key"
        },
        {
            name : "private_secret",
            title : "Private Secret"
        },
        {
            name : "dateCreated",
            title : "Created"
        },
        {
            name : "dateModified",
            title : "Modified"
        },
        {
            name : "actions",
            title : "Actions"
        },
        
    ];