import {createSlice} from "@reduxjs/toolkit";

const initialCategoriesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  categories: null,
  cartegoryForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const categorySlice = createSlice({
  name: "categories",
  initialState: initialCategoriesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    
    categoryFetched: (state, action) => {
      state.actionsLoading = false;
      state.categoryForEdit = action.payload.category;
      state.error = null;
    },
    
    categoriesFetched: (state, action) => {
      
      const { totalCount, categories } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.categories = categories;
      state.totalCount = totalCount;
    },
    
    categoryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.categories.push(action.payload.category);
    },
    
    categoryUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { category } = action.payload;
      state.categories.forEach( (entity, key) => {
        if ( entity.id === category.id ) {
          state.categories[key] = {...category};
        }
      } );
    },
    
    categoryDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { category } = action.payload;
      state.categories.forEach( (entity, key) => {
        if ( entity.id === category.id ) {
            delete state.categories[key];
        }
      } );
    },
    
    categoriesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.categories = state.categories.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    
    categoriesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.categories = state.categories.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    
    categoryReset: (state) => {
      state.categoryForEdit = null;
    },
    
  }
});
